import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../utility/customAxios'

export const getPermissions = createAsyncThunk('permissions/getPermissions', async () => {
    try {
        const response = await axios.get('/permissions')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getPermission = createAsyncThunk('permissions/getPermission', async (id) => {
    try {
        const response = await axios.get(`/permissions/${id}`)
        return response?.data?.item
    } catch (e) {
        console.error(e)
    }
})

export const createPermission = createAsyncThunk('permissions/createPermissions', async (roles) => {
    try {
        const response = await axios.post('/permissions', roles)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deletePermissions = createAsyncThunk('roles/deletePermissions', async (id) => {
    try {
        const response = await axios.delete(`/permissions/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const rolesSlice = createSlice({
    name: 'permissions',
    initialState: {
        permissions: [],
        loadingPermissions: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPermissions.fulfilled, (state, action) => {
                state.permissions = action.payload
                state.loadingPermissions = false
            })
            .addCase(getPermissions.pending, (state) => {
                state.loadingPermissions = true
            })
    }
})

export const {} = rolesSlice.actions

export default rolesSlice.reducer
