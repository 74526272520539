import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../utility/customAxios'

export const getProfiles = createAsyncThunk('accountSettings/getProfiles', async () => {
    try {
        const response = await axios.get('/profiles')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const updateProfiles = createAsyncThunk('accountSettings/updateProfiles', async (profiles) => {
    try {
        const response = await axios.put('/profiles', profiles)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    profiles: null,
    loadingProfiles: false
}

export const usersSlice = createSlice({
    name: 'accountSettings',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProfiles.pending, (state) => {
                state.loadingProfiles = true
            })
            .addCase(getProfiles.fulfilled, (state, action) => {
                state.profiles = action?.payload
                state.loadingProfiles = false
            })
    }
})

export const {} = usersSlice.actions

export default usersSlice.reducer
