import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getChequeBooks = createAsyncThunk('chequeBooks/getChequeBooks', async (params) => {
    try {
        const response = await axios.post('/chequeBooks/list', params)
        return {
            chequeBooks: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getChequeBook = createAsyncThunk('chequeBooks/getChequeBook', async (id) => {
    try {
        const response = await axios.get(`/chequeBooks/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createChequeBook = createAsyncThunk('chequeBooks/createChequeBook', async (chequeBook) => {
    try {
        const response = await axios.post(`/chequeBooks`, chequeBook)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})


export const editChequeBook = createAsyncThunk('chequeBooks/editChequeBook', async (chequeBook) => {
    try {
        const response = await axios.put(`/chequeBooks`, chequeBook)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteChequeBook = createAsyncThunk('chequeBooks/deleteChequeBooks', async (id) => {
    try {
        const response = await axios.delete(`/chequeBooks/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getChequeBooksFilters = createAsyncThunk('chequeBooks/getChequeBooksFilters', async () => {
    try {
        const response = await axios.post(`/chequeBooks/list/filters`)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingChequeBooks: false,
    chequeBooks: [],
    filters: [],
    loadingChequeBooksFilters: false,
    total: 0,
    loadingChequeBook: false,
    selectedChequeBook: null,
    selectedValueSize: 0
}

export const chequeBooksSlice = createSlice({
    name: 'chequeBooks',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getChequeBooks.pending, (state) => {
                state.loadingChequeBooks = true
            })
            .addCase(getChequeBooks.fulfilled, (state, action) => {
                state.chequeBooks = action?.payload?.chequeBooks
                state.total = action?.payload?.total
                state.loadingChequeBooks = false
            })
            .addCase(getChequeBook.pending, (state) => {
                state.loadingChequeBook = true
            })
            .addCase(getChequeBook.fulfilled, (state, action) => {
                state.selectedChequeBook = action?.payload
                state.loadingChequeBook = false
            })
            .addCase(getChequeBooksFilters.pending, (state) => {
                state.loadingChequeBooksFilters = true
            })
            .addCase(getChequeBooksFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingChequeBooksFilters = false
            })
    }
})

export const {} = chequeBooksSlice.actions

export default chequeBooksSlice.reducer
