import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getUserPhones = createAsyncThunk('userPhones/getUserPhones', async ({id, params}) => {
    try {
        const response = await axios.post(`/users/${id}/phones/list`, params)
        return {
            phones: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getUserPhone = createAsyncThunk('userPhones/getUserPhone', async ({id, phoneId}) => {
    try {
        const response = await axios.get(`/users/${id}/phones/${phoneId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createUserPhone = createAsyncThunk('userPhones/createUserPhones', async ({id, params}) => {
    try {
        const response = await axios.post(`/users/${id}/phones`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editUserPhone = createAsyncThunk('userPhones/editUserPhone', async ({id, userPhone}) => {
    try {
        const response = await axios.put(`/users/${id}/phones`, userPhone)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteUserPhone = createAsyncThunk('userPhones/deleteUserPhone', async ({id, phoneId}) => {
    try {
        const response = await axios.delete(`/users/${id}/phones/${phoneId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getUserPhonesFilters = createAsyncThunk('userPhones/getUserPhonesFilters', async (params) => {
    try {
        const response = await axios.post(`/users/${params?.id}/phones/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    phones: [],
    appliedFilters: [],
    selectedPhone: null,
    filters: [],
    loadingPhones: false,
    loadingFilters: false,
    loadingPhone: false,
    selectedValueSize: 0
}

export const userPhonesSlice = createSlice({
    name: 'userPhones',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserPhones.pending, (state) => {
                state.loadingPhones = true
            })
            .addCase(getUserPhones.fulfilled, (state, action) => {
                state.phones = action?.payload?.phones
                state.total = action?.payload?.total
                state.loadingPhones = false
            })

            .addCase(getUserPhone.pending, (state) => {
                state.loadingPhone = true
            })
            .addCase(getUserPhone.fulfilled, (state, action) => {
                state.selectedPhone = action.payload
                state.loadingPhone = false
            })

            .addCase(getUserPhonesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getUserPhonesFilters.fulfilled, (state, action) => {
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.filters
                state.loadingFilters = false
            })
    }
})

export const {} = userPhonesSlice.actions

export default userPhonesSlice.reducer
