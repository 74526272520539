/* Config */
import appConfig from '../../../configs/appConfig'

export default {
    loginEndpoint: `${appConfig.api}/login`,
    registerEndpoint: `${appConfig.api}/users/register`,
    forgetPasswordEndpoint: `${appConfig.api}/forgetPassword`,
    resetPasswordEndpoint: `${appConfig.api}/resetPassword`,
    refreshEndpoint: `${appConfig.api}/users/refresh-token`,
    twoStepVerificationEndpoint: `${appConfig.api}/verify/login`,
    abilities: `${appConfig.api}/abilities`,
    actUsers: `${appConfig.api}/auth/actUsers`,
    actToken: `${appConfig.api}/auth/actToken`,
    actReturnToMain: `${appConfig.api}/auth/returnToMain`,
    verifyUser: `${appConfig.api}/verify/user`,
    verifyUserSendNotification: `${appConfig.api}/verify/user/sendNotification`,

    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
}
