import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

// ** Delivery Receipts
export const getDeliveryReceipts = createAsyncThunk('deliveryReceipts/getDeliveryReceipts', async (params) => {
    try {
        const response = await axios.post('/delivery-receipts/list', params)
        return {
            deliveryReceipts: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDeliveryReceiptsFilters = createAsyncThunk('deliveryReceipts/getDeliveryReceiptsFilters', async (params) => {
    try {
        const response = await axios.post(`/delivery-receipts/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDeliveryReceipt = createAsyncThunk('deliveryReceipts/getDeliveryReceipt', async (id) => {
    try {
        const response = await axios.get(`/delivery-receipts/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})
export const deleteDeliveryReceipt = createAsyncThunk('deliveryReceipts/deleteDeliveryReceipt', async (id) => {
    try {
        const response = await axios.delete(`/delivery-receipts/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

// ** Delivery Receipts Control
export const createCreationsControlPayments = createAsyncThunk('deliveryReceipts/createCreationsControlPayments', async (params) => {
    try {
        const response = await axios.post('/delivery-receipts/creations/first/control-payments', params)
        return {
            creationsControlPayments: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const createCreationsSupplier = createAsyncThunk('deliveryReceipts/createCreationsSupplier', async (params) => {
    try {
        const response = await axios.post('/delivery-receipts/creations/second/suppliers', params)
        return {
            creationSupplier: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const createCreationsItems = createAsyncThunk('deliveryReceipts/createCreationsItems', async (params) => {
    try {
        const response = await axios.post('/delivery-receipts/creations/third/items', params)
        return {
            creationItems: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const createCreationsReceivers = createAsyncThunk('deliveryReceipts/createCreationsReceivers', async (params) => {
    try {
        const response = await axios.post('/delivery-receipts/creations/fourth/receivers', params)
        return {
            creationsReceivers: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

// ** Delivery Receipts Items
export const getDeliveryReceiptItems = createAsyncThunk('deliveryReceipts/getDeliveryReceiptItems', async (params) => {
    try {
        const response = await axios.post(`/delivery-receipts/${params?.deliveryReceiptId}/items/list`, params)
        return {
            deliveryReceiptItems: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDeliveryReceiptItemsFilters = createAsyncThunk('deliveryReceipts/getDeliveryReceiptItemsFilters', async (params) => {
    try {
        const response = await axios.post(`/delivery-receipts/${params?.deliveryReceiptId}/items/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})
export const deleteDeliveryReceiptItem = createAsyncThunk('deliveryReceipts/deleteDeliveryReceiptItem', async (params) => {
    try {
        const response = await axios.delete(`/delivery-receipts/${params?.deliveryReceiptId}/items/${params?.deliveryReceiptItemId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const getDeliveryReceiptItem = createAsyncThunk('deliveryReceipts/getDeliveryReceiptItem', async (params) => {
    try {
        const response = await axios.get(`/delivery-receipts/${params?.deliveryReceiptId}/items`)
        return {
            selectedDeliveryReceiptItem: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDeliveryReceiptItemsSearch = createAsyncThunk('deliveryReceipts/getDeliveryReceiptItemsSearch', async (params) => {
    const data = []
    try {
        const response = await axios.get(`/delivery-receipts/${params?.deliveryReceiptId}/items`, {
            params
        })
        response?.data?.items.map(item => data.push({value: item?.id, label: item?.title}))
        return data
    } catch (e) {
        console.error(e)
    }
})

// ** Delivery Receipts Packages
export const getDeliveryReceiptPackages = createAsyncThunk('deliveryReceipts/getDeliveryReceiptPackages', async (params) => {
    try {
        const response = await axios.post(`/delivery-receipts/${params?.deliveryReceiptId}/items/${params?.deliveryReceiptItemId}/packages/list`, params)
        return {
            packages: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDeliveryReceiptPackagesFilters = createAsyncThunk('deliveryReceipts/getDeliveryReceiptPackagesFilters', async (params) => {
    try {
        const response = await axios.post(`/delivery-receipts/${params?.deliveryReceiptId}/items/${params?.deliveryReceiptItemId}/packages/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

// ** Delivery Receipts Product
export const createDeliveryReceiptProduct = createAsyncThunk('deliveryReceipts/createDeliveryReceiptProduct', async (params) => {
    try {
        const response = await axios.post(`/delivery-receipts/creations/third/items/${params?.deliveryReceiptItemId}/products`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDeliveryReceiptProduct = createAsyncThunk('deliveryReceipts/deleteDeliveryReceiptProduct', async (params) => {
    try {
        const response = await axios.delete(`/delivery-receipts/${params?.deliveryReceiptId}/items/${params?.deliveryReceiptItemId}/products/${params?.productId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    deliveryReceipts: [],
    loadingDeliveryReceipts: false,
    filters: [],
    loadingDeliveryReceiptsFilters: false,
    selectedValueSize: 0,
    selectedDeliveryReceipt: null,
    loadingDeliveryReceipt: false,
    creationsControlPayments: [],
    loadingCreationsControlPayments: false,
    creationsItems: [],
    loadingCreationsItems: false,
    creationsSuppliers: [],
    loadingCreationsSuppliers: false,
    creationsReceivers: [],
    loadingCreationsReceivers: false,
    deliveryReceiptItems: [],
    loadingDeliveryReceiptItems: false,
    deliveryReceiptItemsFilters: [],
    loadingDeliveryReceiptItemsFilters: false,
    selectedDeliveryReceiptItemsValueSize: 0,
    totalItems: 0,
    packages: [],
    loadingPackages: false,
    filtersPackages: [],
    loadingFiltersPackages: false,
    totalPackages: 0,
    loadingDeliveryReceiptItem: false,
    selectedDeliveryReceiptItem: null
}

export const DeliveryReceiptsSlice = createSlice({
    name: 'deliveryReceipts',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDeliveryReceipts.pending, (state) => {
                state.loadingDeliveryReceipts = true
            })
            .addCase(getDeliveryReceipts.fulfilled, (state, action) => {
                state.deliveryReceipts = action?.payload?.deliveryReceipts
                state.total = action?.payload?.total
                state.loadingDeliveryReceipts = false
            })

            .addCase(getDeliveryReceipt.pending, (state) => {
                state.loadingDeliveryReceipt = true
            })
            .addCase(getDeliveryReceipt.fulfilled, (state, action) => {
                state.selectedDeliveryReceipt = action?.payload
                state.loadingDeliveryReceipt = false
            })

            .addCase(getDeliveryReceiptsFilters.pending, (state) => {
                state.loadingDeliveryReceiptsFilters = true
            })
            .addCase(getDeliveryReceiptsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingDeliveryReceiptsFilters = false
            })

            // ** Items
            .addCase(getDeliveryReceiptItems.pending, (state) => {
                state.loadingDeliveryReceiptItems = true
            })
            .addCase(getDeliveryReceiptItems.fulfilled, (state, action) => {
                state.deliveryReceiptItems = action?.payload?.deliveryReceiptItems
                state.totalItems = action?.payload?.total
                state.loadingDeliveryReceiptItems = false
            })

            .addCase(getDeliveryReceiptItemsFilters.pending, (state) => {
                state.loadingDeliveryReceiptItemsFilters = true
            })
            .addCase(getDeliveryReceiptItemsFilters.fulfilled, (state, action) => {
                state.deliveryReceiptItemsFilters = action?.payload?.filters
                state.selectedDeliveryReceiptItemsValueSize = action?.payload?.selectedValueSize
                state.loadingDeliveryReceiptItemsFilters = false
            })

            .addCase(getDeliveryReceiptItem.pending, (state) => {
                state.loadingPackages = true
            })
            .addCase(getDeliveryReceiptItem.fulfilled, (state, action) => {
                state.selectedDeliveryReceiptItem = action?.payload?.selectedDeliveryReceiptItem
                state.loadingPackages = false
            })

            // ** Invoices
            .addCase(getDeliveryReceiptPackages.pending, (state) => {
                state.loadingFiltersPackages = true
            })
            .addCase(getDeliveryReceiptPackages.fulfilled, (state, action) => {
                state.packages = action?.payload?.packages
                state.totalPackages = action?.payload?.total
                state.loadingFiltersPackages = false
            })

            .addCase(getDeliveryReceiptPackagesFilters.pending, (state) => {
                state.loadingDeliveryReceiptItemsFilters = true
            })
            .addCase(getDeliveryReceiptPackagesFilters.fulfilled, (state, action) => {
                state.deliveryReceiptItemsFilters = action?.payload?.filters
                state.selectedDeliveryReceiptItemsValueSize = action?.payload?.selectedValueSize
                state.loadingDeliveryReceiptItemsFilters = false
            })
    }
})

export const {} = DeliveryReceiptsSlice.actions

export default DeliveryReceiptsSlice.reducer
