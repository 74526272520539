import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

// ** Users
export const getUserBalance = createAsyncThunk('balance/getUserBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/users/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Users

// ** Customers
export const getCustomerBalance = createAsyncThunk('balance/getCustomerBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/customers/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Customers

// ** Suppliers
export const getSupplierBalance = createAsyncThunk('balance/getSupplierBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/suppliers/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Suppliers

// ** Departments
export const getDepartmentBalance = createAsyncThunk('balance/getDepartmentBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/departments/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Departments

// ** Checkouts
export const getCheckoutBalance = createAsyncThunk('balance/getCheckoutsBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/checkouts/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Checkouts

// ** BankAccounts
export const getBankAccountBalance = createAsyncThunk('balance/getBankAccountBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/bankAccounts/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End BankAccounts

// ** My Balance
export const getMyBalance = createAsyncThunk('balance/getMyBalance', async () => {
    try {
        const response = await axios.get(`/balances/my-balances`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Balance

// ** User Banks Balance
export const getUserBanksBalance = createAsyncThunk('balance/getUserBanksBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/users/${id}/banks`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End User Banks Balance

// ** Customers Banks Balance
export const getCustomerBanksBalance = createAsyncThunk('balance/getCustomerBanksBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/customers/${id}/banks`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** Customers Banks Balance

// ** Suppliers Banks Balance
export const getSupplierBanksBalance = createAsyncThunk('balance/getSupplierBanksBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/suppliers/${id}/banks`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** Suppliers Banks Balance

// ** Departments Banks Balance
export const getDepartmentBanksBalance = createAsyncThunk('balance/getDepartmentBanksBalance', async (
    {
        id
    }) => {
    try {
        const response = await axios.get(`/balances/departments/${id}/banks`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** Departments Banks Balance

const initialState = {
    loadingBalance: false,
    balances: []
}

export const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getUserBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getCustomerBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getCustomerBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getSupplierBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getSupplierBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getDepartmentBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getDepartmentBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getCheckoutBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getCheckoutBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getBankAccountBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getBankAccountBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getMyBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getMyBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getUserBanksBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getUserBanksBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getCustomerBanksBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getCustomerBanksBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getSupplierBanksBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getSupplierBanksBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })

            .addCase(getDepartmentBanksBalance.pending, (state) => {
                state.loadingBalance = true
            })
            .addCase(getDepartmentBanksBalance.fulfilled, (state, action) => {
                state.loadingBalance = false
                state.balances = action.payload
            })
    }
})

export const {reset} = balanceSlice.actions

export default balanceSlice.reducer
