import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getReports = createAsyncThunk('reports/getReports', async (params) => {
    try {
        const response = await axios.post(`/reports/balances/list`, params)
        return {
            reports: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getReportsAmount = createAsyncThunk('reports/getReportsAmount', async (params) => {
    try {
        const response = await axios.post(`/reports/balances/list/amount`, params)
        return {
            reportsAmount: response?.data?.items
        }
    } catch (e) {
        console.error(e)
    }
})

export const getReportsFilters = createAsyncThunk('reports/getReportsFilters', async (params) => {
    try {
        const response = await axios.post(`/reports/balances/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingReports: false,
    loadingReportsAmount: false,
    reportsAmount: [],
    reports: [],
    filters: [],
    loadingReportsFilters: false,
    total: 0,
    selectedValueSize: 0
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getReports.pending, (state) => {
                state.loadingReports = true
            })
            .addCase(getReports.fulfilled, (state, action) => {
                state.reports = action?.payload?.reports
                state.total = action?.payload?.total
                state.loadingReports = false
            })

            .addCase(getReportsAmount.pending, (state) => {
                state.loadingReportsAmount = true
            })
            .addCase(getReportsAmount.fulfilled, (state, action) => {
                state.reportsAmount = action?.payload?.reportsAmount
                state.loadingReportsAmount = false
            })

            .addCase(getReportsFilters.pending, (state) => {
                state.loadingReportsFilters = true
            })
            .addCase(getReportsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingReportsFilters = false
            })
    }
})

export const {} = reportsSlice.actions

export default reportsSlice.reducer
