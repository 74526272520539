import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const createUserDepartments = createAsyncThunk('userDepartments/createUserDepartments', async ({id, params}) => {
    try {
        const response = await axios.post(`/users/${id}/departments`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteUserDepartments = createAsyncThunk('userDepartments/deleteUserDepartments', async ({id, departmentId}) => {
    try {
        const response = await axios.delete(`/users/${id}/departments/${departmentId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {}

export const userDepartmentsSlice = createSlice({
    name: 'userDepartments',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    }
})

export const {} = userDepartmentsSlice.actions

export default userDepartmentsSlice.reducer
