import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getTaxOffices = createAsyncThunk('taxOffices/getTaxOffices', async (params) => {
    try {
        const response = await axios.post('/taxOffices/list', params)
        return {
            taxOffices: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTaxOffice = createAsyncThunk('taxOffices/getTaxOffice', async (id) => {
    try {
        const response = await axios.get(`/taxOffices/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createTaxOffice = createAsyncThunk('taxOffices/createTaxOffice', async (params) => {
    try {
        const response = await axios.post(`/taxOffices`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})


export const editTaxOffice = createAsyncThunk('taxOffices/editTaxOffice', async (params) => {
    try {
        const response = await axios.put(`/taxOffices`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteTaxOffice = createAsyncThunk('taxOffices/deleteTaxOffice', async (id) => {
    try {
        const response = await axios.delete(`/taxOffices/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getTaxOfficesFilters = createAsyncThunk('taxOffices/getTaxOfficesFilters', async () => {
    try {
        const response = await axios.post(`/taxOffices/list/filters`)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingTaxOffices: false,
    taxOffices: [],
    filters: [],
    loadingFilters: false,
    total: 0,
    loadingTaxOffice: false,
    selectedTaxOffice: null,
    selectedValueSize: 0
}

export const taxOfficesSlice = createSlice({
    name: 'taxOffices',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTaxOffices.pending, (state) => {
                state.loadingTaxOffices = true
            })
            .addCase(getTaxOffices.fulfilled, (state, action) => {
                state.taxOffices = action?.payload?.taxOffices
                state.total = action?.payload?.total
                state.loadingTaxOffices = false
            })

            .addCase(getTaxOffice.pending, (state) => {
                state.loadingTaxOffice = true
            })
            .addCase(getTaxOffice.fulfilled, (state, action) => {
                state.selectedTaxOffice = action?.payload
                state.loadingTaxOffice = false
            })

            .addCase(getTaxOfficesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getTaxOfficesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingFilters = false
            })
    }
})

export const {} = taxOfficesSlice.actions

export default taxOfficesSlice.reducer
