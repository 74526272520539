import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getExpenses = createAsyncThunk('expenses/getExpenses', async (params) => {
    try {
        const response = await axios.post('/expenses/list', params)
        return {
            expenses: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getExpense = createAsyncThunk('expenses/getExpense', async (id) => {
    try {
        const response = await axios.get(`/expenses/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createExpense = createAsyncThunk('expenses/createExpense', async (params) => {
    try {
        const response = await axios.post(`/expenses`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editExpense = createAsyncThunk('expenses/editExpense', async (params) => {
    try {
        const response = await axios.put(`/expenses`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteExpense = createAsyncThunk('expenses/deleteExpense', async (id) => {
    try {
        const response = await axios.delete(`/expenses/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getExpensesFilters = createAsyncThunk('expenses/getExpensesFilters', async (params) => {
    try {
        const response = await axios.post(`/expenses/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingExpenses: false,
    expenses: [],
    filters: [],
    total: 0,
    loadingExpense: false,
    loadingExpensesFilters: false,
    selectedExpense: null,
    selectedValueSize: 0
}

export const expensesSlice = createSlice({
    name: 'expenses',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getExpenses.pending, (state) => {
                state.loadingExpenses = true
            })
            .addCase(getExpenses.fulfilled, (state, action) => {
                state.expenses = action?.payload?.expenses
                state.total = action?.payload?.total
                state.loadingExpenses = false
            })
            .addCase(getExpense.pending, (state) => {
                state.loadingExpense = true
            })
            .addCase(getExpense.fulfilled, (state, action) => {
                state.selectedExpense = action?.payload
                state.loadingExpense = false
            })
            .addCase(getExpensesFilters.pending, (state) => {
                state.loadingExpensesFilters = true
            })
            .addCase(getExpensesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingExpensesFilters = false
            })
    }
})

export const {} = expensesSlice.actions

export default expensesSlice.reducer
