import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getSupplierWorkers = createAsyncThunk('supplierWorkers/getSupplierWorkers', async ({supplierId, params}) => {
    try {
        const response = await axios.post(`/suppliers/${supplierId}/workers/list`, params)
        return {
            supplierWorkers: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

export const getSupplierWorker = createAsyncThunk('supplierWorkers/getSupplierWorker', async ({supplierId, workerId}) => {
    try {
        const response = await axios.get(`/suppliers/${supplierId}/workers/${workerId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createSupplierWorker = createAsyncThunk('supplierWorkers/createSupplierWorker', async ({supplierId, params}) => {
    try {
        const response = await axios.post(`/suppliers/${supplierId}/workers`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editSupplierWorker = createAsyncThunk('supplierWorkers/editSupplierWorker', async ({supplierId, params}) => {
    try {
        const response = await axios.put(`/suppliers/${supplierId}/workers`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteSupplierWorker = createAsyncThunk('supplierWorkers/deleteSupplierWorker', async ({supplierId, workerId}) => {
    try {
        const response = await axios.delete(`/suppliers/${supplierId}/workers/${workerId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getSuppliersWorkersFilters = createAsyncThunk('supplierWorkers/getSuppliersFilters', async ({supplierId, params}) => {
    try {
        const response = await axios.post(`/suppliers/${supplierId}/workers/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    supplierWorkers: [],
    appliedFilters: [],
    selectedSupplierWorker: null,
    filters: [],
    loadingSupplierWorkers: false,
    loadingFilters: false,
    loadingSupplierWorker: false,
    selectedValueSize: 0
}

export const supplierWorkersSlice = createSlice({
    name: 'supplierWorkers',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSupplierWorkers.pending, (state) => {
                state.loadingSupplierWorkers = true
            })
            .addCase(getSupplierWorkers.fulfilled, (state, action) => {
                state.supplierWorkers = action?.payload?.supplierWorkers
                state.total = action?.payload?.total
                state.loadingSupplierWorkers = false
            })

            .addCase(getSupplierWorker.pending, (state) => {
                state.loadingSupplierWorker = true
            })
            .addCase(getSupplierWorker.fulfilled, (state, action) => {
                state.selectedSupplierWorker = action.payload
                state.loadingSupplierWorker = false
            })

            .addCase(getSuppliersWorkersFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSuppliersWorkersFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
            })
    }
})

export const {} = supplierWorkersSlice.actions

export default supplierWorkersSlice.reducer
