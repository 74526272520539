import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../utility/customAxios'

export const getPrefixes = createAsyncThunk('prefixes/getPrefixes', async (params) => {
    try {
        const response = await axios.post('/prefixes/list', params)
        return {
            prefixes: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getPrefixesSearch = createAsyncThunk('prefixes/getPrefixesSearch', async (params) => {
    try {
        const response = await axios.get(`/prefixes`, {
            params: {
                countryId: params.countryId
            }
        })
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})

export const getPrefixesFilters = createAsyncThunk('prefixes/getPrefixesFilters', async (params) => {
    try {
        const response = await axios.post(`/prefixes/list/filters`, params)
        return response.data
    } catch (e) {
        console.error(e)
    }
})


export const getPrefix = createAsyncThunk('prefixes/getPrefix', async (id) => {
    try {
        const response = await axios.get(`/prefixes/${id}`)
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})

export const createPrefix = createAsyncThunk('prefixes/createPrefix', async (params) => {
    try {
        const response = await axios.post('/prefixes', params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editPrefix = createAsyncThunk('prefixes/editPrefix', async (params) => {
    try {
        const response = await axios.put(`/prefixes`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deletePrefix = createAsyncThunk('taxes/deleteTax', async (id) => {
    try {
        const response = await axios.delete(`/prefixes/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    prefixes: [],
    prefixesSearch: [],
    total: null,
    filters: [],
    loadingPrefixes: false,
    loadingPrefix: false,
    selectedPrefix: null,
    loadingFilters: false,
    loadingPrefixesSearch: false,
    selectedValueSize: null
}

export const prefixesSlice = createSlice({
    name: 'prefixes',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPrefixes.pending, (state) => {
                state.loadingPrefixes = true
            })
            .addCase(getPrefixes.fulfilled, (state, action) => {
                state.prefixes = action?.payload?.prefixes
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingPrefixes = false
            })

            .addCase(getPrefixesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getPrefixesFilters.fulfilled, (state, action) => {
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.items
                state.loadingFilters = false
            })

            .addCase(getPrefix.pending, (state) => {
                state.loadingPrefix = true
            })
            .addCase(getPrefix.fulfilled, (state, action) => {
                state.selectedPrefix = action?.payload
                state.loadingPrefix = false
            })

            .addCase(getPrefixesSearch.pending, (state) => {
                state.loadingPrefixesSearch = true
            })
            .addCase(getPrefixesSearch.fulfilled, (state, action) => {
                state.prefixesSearch = action?.payload
                state.loadingPrefixesSearch = false
            })
    }
})

export const {} = prefixesSlice.actions

export default prefixesSlice.reducer
