import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getCurrencies = createAsyncThunk('currencies/getCurrencies', async (params) => {
    try {
        const response = await axios.post('/currencies/list', params)
        return {
            currencies: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCurrency = createAsyncThunk('currencies/getCurrency', async (id) => {
    try {
        const response = await axios.get(`/currencies/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editCurrency = createAsyncThunk('currencies/editCurrency', async (currency) => {
    try {
        const response = await axios.put(`/currencies`, currency)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getCurrenciesFilters = createAsyncThunk('currencies/getCurrenciesFilters', async (params) => {
    try {
        const response = await axios.post(`/currencies/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCurrenciesSearch = createAsyncThunk('currencies/getCurrenciesSearch', async () => {
    const data = []
    try {
        const response = await axios.get('/currencies')
        response.data.items.map(item => data.push({value: item.code, label: item.name}))
        return data
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingCurrencies: false,
    currencies: [],
    filters: [],
    total: 0,
    loadingCurrency: false,
    loadingCurrenciesFilters: false,
    selectedCurrency: null,
    selectedValueSize: 0
}

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCurrencies.pending, (state) => {
                state.loadingCurrencies = true
            })
            .addCase(getCurrencies.fulfilled, (state, action) => {
                state.currencies = action?.payload?.currencies
                state.total = action?.payload?.total
                state.loadingCurrencies = false
            })

            .addCase(getCurrency.pending, (state) => {
                state.loadingCurrency = true
            })
            .addCase(getCurrency.fulfilled, (state, action) => {
                state.selectedCurrency = action?.payload
                state.loadingCurrency = false
            })

            .addCase(getCurrenciesFilters.pending, (state) => {
                state.loadingCurrenciesFilters = true
            })
            .addCase(getCurrenciesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingCurrenciesFilters = false
            })

            .addCase(getCurrenciesSearch.pending, (state) => {
                state.loadingCurrenciesSearch = true
            })
            .addCase(getCurrenciesSearch.fulfilled, (state, action) => {
                state.currenciesSearch = action?.payload
                state.loadingCurrenciesSearch = false
            })

    }
})

export const {} = currenciesSlice.actions

export default currenciesSlice.reducer
