import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getProductUnits = createAsyncThunk('productUnits/getProductUnits', async () => {
    try {
        const response = await axios.get('/productUnits')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    productUnits: [],
    loadingProductUnits: false
}

export const productUnitsSlice = createSlice({
    name: 'productUnits',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProductUnits.pending, (state) => {
                state.loadingProductUnits = true
            })
            .addCase(getProductUnits.fulfilled, (state, action) => {
                state.productUnits = action?.payload
                state.loadingProductUnits = false
            })
    }
})

export const {} = productUnitsSlice.actions

export default productUnitsSlice.reducer
