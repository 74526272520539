import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

export const getUsers = createAsyncThunk('users/getUsers', async (params) => {
    try {
        const response = await axios.post('/users/list', params)
        return {
            users: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getUser = createAsyncThunk('users/getUser', async (userId) => {
    try {
        const response = await axios.get(`/users/${userId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createUser = createAsyncThunk('users/createUser', async (user) => {
    try {
        const response = await axios.post('/users', user)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editUser = createAsyncThunk('users/editUser', async (user) => {
    try {
        const response = await axios.put(`/users`, user)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (id) => {
    try {
        const response = await axios.delete(`/users/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const getDashboardUser = createAsyncThunk('users/getDashboardUser', async () => {
    try {
        const response = await axios.get(`/dashboard/userCounts`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getUserFilters = createAsyncThunk('users/getUserFilters', async (params) => {
    try {
        const response = await axios.post(`/users/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getUserRoles = createAsyncThunk('users/getUserRoles', async (userId) => {
    try {
        const response = await axios.get(`/users/${userId}/roles`)
        return response?.data?.items?.roles
    } catch (e) {
        console.error(e)
    }
})

export const getUserPermissions = createAsyncThunk('users/getUserPermissions', async (userId) => {
    try {
        const response = await axios.get(`/users/${userId}/permissions`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const setUserRoles = createAsyncThunk('users/setUserRoles', async (params) => {
    try {
        const response = await axios.post(`/users/setRoles`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const setUserPermissions = createAsyncThunk('users/setUserPermissions', async (params) => {
    try {
        const response = await axios.post(`/users/setPermissions`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const sendVerifySendNotification = createAsyncThunk('users/setUserPermissions', async (userId) => {
    try {
        const response = await axios.get(`/users/${userId}/verify/sendNotification`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getUserBalance = createAsyncThunk('users/getUserBalance', async (userId) => {
    try {
        const response = await axios.get(`/balances/users/${userId} `)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    users: [],
    total: null,
    appliedFilters: [],
    dashboardUser: null,
    selectedUser: null,
    filters: [],
    loadingUsers: false,
    loadingUser: false,
    loadingDashboardUser: false,
    loadingFilters: true,
    loadingUserRoles: false,
    userRoles: [],
    loadingUserPermissions: false,
    userPermissions: [],
    balances: [],
    loadingUserBalance: false,
    selectedValueSize: 0
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loadingUsers = true
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users = action?.payload?.users
                state.total = action?.payload?.total
                state.loadingUsers = false
            })

            .addCase(getUser.pending, (state) => {
                state.loadingUser = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
                state.loadingUser = false
            })

            .addCase(getDashboardUser.pending, (state) => {
                state.loadingDashboardUser = true
            })
            .addCase(getDashboardUser.fulfilled, (state, action) => {
                state.dashboardUser = action.payload
                state.loadingDashboardUser = false
            })

            .addCase(getUserFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getUserFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.filters
            })

            .addCase(getUserRoles.pending, (state) => {
                state.loadingUserRoles = true
            })
            .addCase(getUserRoles.fulfilled, (state, action) => {
                state.loadingUserRoles = false
                state.userRoles = action.payload
            })

            .addCase(getUserPermissions.pending, (state) => {
                state.loadingUserPermissions = true
                state.userPermissions = []
            })
            .addCase(getUserPermissions.fulfilled, (state, action) => {
                state.loadingUserPermissions = false
                state.userPermissions = action.payload
            })
            .addCase(getUserBalance.pending, (state) => {
                state.loadingUserBalance = true
            })
            .addCase(getUserBalance.fulfilled, (state, action) => {
                state.loadingUserBalance = false
                state.balances = action.payload
            })
    }
})

export const {} = usersSlice.actions

export default usersSlice.reducer
