import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getCheque = createAsyncThunk('cheques/getCheque', async (id) => {
    try {
        const response = await axios.get(`/cheques/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editCheque = createAsyncThunk('cheques/editCheque', async (cheque) => {
    try {
        const response = await axios.put(`/cheques`, cheque)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteCheque = createAsyncThunk('cheques/deleteCheque', async (id) => {
    try {
        const response = await axios.delete(`/cheque/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    selectedCheque: null,
    loadingCheque: false
}

export const chequesSlice = createSlice({
    name: 'cheques',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCheque.pending, (state) => {
                state.loadingCheque = true
            })
            .addCase(getCheque.fulfilled, (state, action) => {
                state.selectedCheque = action?.payload
                state.loadingCheque = false
            })
    }
})

export const {} = chequesSlice.actions

export default chequesSlice.reducer
