import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getMyTransactions = createAsyncThunk('myTransactions/getMyTransactions', async (params) => {
    try {
        const response = await axios.post(`/my-transactions/list`, params)
        return {
            myTransactions: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getMyTransactionsFilters = createAsyncThunk('myTransactions/getMyTransactionsFilters', async (params) => {
    try {
        const response = await axios.post(`/my-transactions/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getMyTransaction = createAsyncThunk('myTransactions/getMyTransaction', async (id) => {
    try {
        const response = await axios.get(`/my-transactions/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getMyTransactionsAmount = createAsyncThunk('myTransactions/getMyTransactionsAmount', async (params) => {
    try {
        const response = await axios.post(`/my-transactions/list/amounts`, params)
        return {
            transactionsAmount: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getPDF = createAsyncThunk('myTransactions/getPDF', async (params) => {
    try {
        const response = await axios.post('pdf/transactions/list', params, {
            timeout: 1000 * 60 * 2
        })
        return {
            pdf: response?.data
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: 0,
    myTransactions: [],
    filters: [],
    loadingMyTransactions: false,
    loadingMyTransactionsFilters: false,
    selectedValueSize: 0,
    transactionsAmount: [],
    loadingTransactionsAmount: false,
    loadingMyTransaction: false,
    loadingPDF: false,
    selectedMyTransaction: null
}

export const transactionsSlice = createSlice({
    name: 'myTransactions',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getMyTransactions.pending, (state) => {
                state.loadingTransactions = true
            })
            .addCase(getMyTransactions.fulfilled, (state, action) => {
                state.myTransactions = action?.payload?.myTransactions
                state.total = action?.payload?.total
                state.loadingMyTransactions = false
            })

            .addCase(getMyTransactionsAmount.pending, (state) => {
                state.loadingTransactionsAmount = true
            })
            .addCase(getMyTransactionsAmount.fulfilled, (state, action) => {
                state.transactionsAmount = action?.payload?.transactionsAmount
                state.loadingTransactionsAmount = false
            })

            .addCase(getMyTransactionsFilters.pending, (state) => {
                state.loadingMyTransactionsFilters = true
            })
            .addCase(getMyTransactionsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingMyTransactionsFilters = false
            })

            .addCase(getMyTransaction.pending, (state) => {
                state.loadingMyTransaction = true
            })
            .addCase(getMyTransaction.fulfilled, (state, action) => {
                state.selectedMyTransaction = action?.payload
                state.loadingMyTransaction = false
            })

            /* PDF */
            .addCase(getPDF.pending, (state) => {
                state.loadingPDF = true
            })
            .addCase(getPDF.fulfilled, (state, action) => {
                state.loadingPDF = false
                state.pdf = action?.payload
            })
    }
})

export const {} = transactionsSlice.actions

export default transactionsSlice.reducer
