import ReactDOM from 'react-dom'
import {Suspense, lazy, useMemo} from 'react'
import {BrowserRouter, useLocation, useNavigate} from 'react-router-dom'
import {store} from './redux/store'
import {Provider} from 'react-redux'
import {Toaster} from 'react-hot-toast'
import {QueryParamProvider} from 'use-query-params'

// ** Context
import {AbilityContext} from './utility/context/Can'
import {ThemeContext} from './utility/context/ThemeColors'

// ** Configs
import themeConfig from '@configs/themeConfig'
import ability from '@configs/acl/ability'
import '@configs/i18n'

// ** Components
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/components/ripple-button'

import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** Styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'
import 'react-phone-input-2/lib/material.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

const RouteAdapter = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const adaptedHistory = useMemo(
        () => ({
            replace(location) {
                navigate(location, { replace: true, state: location.state })
            },
            push(location) {
                navigate(location, { replace: false, state: location.state })
            }
        }),
        [navigate]
    )
    return children({ history: adaptedHistory, location })
}

const LazyApp = lazy(() => import('./App'))
ReactDOM.render(
    <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
            <Provider store={store}>
                <Suspense fallback={<Spinner/>}>
                    <AbilityContext.Provider value={ability}>
                        <ThemeContext>
                            <LazyApp/>
                            <Toaster
                                position={themeConfig.layout.toastPosition}
                                toastOptions={{className: 'react-hot-toast'}}/>
                        </ThemeContext>
                    </AbilityContext.Provider>
                </Suspense>
            </Provider>
        </QueryParamProvider>
    </BrowserRouter>,
    document.getElementById('root')
)

serviceWorker.register()
