import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getExchangeRates = createAsyncThunk('exchangeRates/getExchangeRates', async (params) => {
    try {
        const response = await axios.get('/exchange-rates/list', params)
        return {
            exchangeRates: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getExchangeRate = createAsyncThunk('exchangeRates/getExchangeRate', async (params) => {
    try {
        const response = await axios.get(`/exchange-rates`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editExchangeRate = createAsyncThunk('exchangeRates/editExchangeRate', async (params) => {
    try {
        const response = await axios.put(`/exchange-rates`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingExchangeRates: false,
    exchangeRates: [],
    total: 0,
    loadingExchangeRate: false,
    selectedExchangeRate: null
}

export const exchangeRatesSlice = createSlice({
    name: 'exchangeRates',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getExchangeRates.pending, (state) => {
                state.loadingExchangeRates = true
            })
            .addCase(getExchangeRates.fulfilled, (state, action) => {
                state.exchangeRates = action?.payload?.exchangeRates
                state.total = action?.payload?.total
                state.loadingExchangeRates = false
            })
            .addCase(getExchangeRate.pending, (state) => {
                state.loadingExchangeRate = true
            })
            .addCase(getExchangeRate.fulfilled, (state, action) => {
                state.selectedExchangeRate = action?.payload
                state.loadingExchangeRate = false
            })
    }
})

export const {} = exchangeRatesSlice.actions

export default exchangeRatesSlice.reducer
