import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getBaseCurrencies = createAsyncThunk('baseCurrencies/getBaseCurrencies', async () => {
    try {
        const response = await axios.get('/base-currencies')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editBaseCurrencies = createAsyncThunk('baseCurrencies/editBaseCurrencies', async (currency) => {
    try {
        const response = await axios.put(`/base-currencies`, currency)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingBaseCurrencies: false,
    baseCurrencies: []
}

export const baseCurrenciesSlice = createSlice({
    name: 'baseCurrencies',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBaseCurrencies.pending, (state) => {
                state.loadingBaseCurrencies = true
            })
            .addCase(getBaseCurrencies.fulfilled, (state, action) => {
                state.baseCurrencies = action?.payload
                state.loadingBaseCurrencies = false
            })
    }
})

export const {} = baseCurrenciesSlice.actions

export default baseCurrenciesSlice.reducer
