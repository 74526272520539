import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getPayments = createAsyncThunk('payments/getPayments', async (params) => {
    try {
        const response = await axios.post('/payments/list', params)
        return {
            payments: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getPaymentsFilters = createAsyncThunk('payments/getPaymentsFilters', async (params) => {
    try {
        const response = await axios.post(`/payments/list/filters`, params)
        return response.data
    } catch (e) {
        console.error(e)
    }
})

export const getPaymentsSearch = createAsyncThunk('payments/getPaymentsSearch', async (params) => {
    try {
        const response = await axios.get(`/prefixes`, {
            params: {
                countryId: params.countryId
            }
        })
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})

export const getPayment = createAsyncThunk('payments/getPrefix', async (id) => {
    try {
        const response = await axios.get(`/payments/${id}`)
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})

export const createPayment = createAsyncThunk('payments/createPayment', async (params) => {
    try {
        const response = await axios.post('/payments', params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editPayment = createAsyncThunk('payments/editPayment', async (params) => {
    try {
        const response = await axios.put(`/payments`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deletePayment = createAsyncThunk('payments/deletePayment', async (id) => {
    try {
        const response = await axios.delete(`/payments/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    payments: [],
    prefixesSearch: [],
    total: null,
    filters: [],
    loadingPayments: false,
    loadingPayment: false,
    selectedPayment: null,
    loadingFilters: false,
    loadingPaymentsSearch: false,
    selectedValueSize: null
}

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPayments.pending, (state) => {
                state.loadingPayments = true
            })
            .addCase(getPayments.fulfilled, (state, action) => {
                state.payments = action?.payload?.payments
                state.total = action?.payload?.total
                state.loadingPayments = false
            })

            .addCase(getPaymentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getPaymentsFilters.fulfilled, (state, action) => {
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.items
                state.loadingFilters = false
            })

            .addCase(getPayment.pending, (state) => {
                state.loadingPayment = true
            })
            .addCase(getPayment.fulfilled, (state, action) => {
                state.selectedPayment = action?.payload
                state.loadingPayment = false
            })

            .addCase(getPaymentsSearch.pending, (state) => {
                state.loadingPaymentsSearch = true
            })
            .addCase(getPaymentsSearch.fulfilled, (state, action) => {
                state.paymentsSearch = action?.payload
                state.loadingPaymentsSearch = false
            })
    }
})

export const {} = paymentsSlice.actions

export default paymentsSlice.reducer
