import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

// ** Users
export const getUserBalanceLimits = createAsyncThunk('balanceLimits/getUserBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/users/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getUserBalanceLimitsFilters = createAsyncThunk('balanceLimits/getUserBalanceLimitsFilters', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/users/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createUserBalanceLimits = createAsyncThunk('balanceLimits/createUserBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/users/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const editUserBalanceLimits = createAsyncThunk('balanceLimits/editUserBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.put(`/balance-limits/users/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

// ** End Users

// ** Customers
export const getCustomerBalanceLimits = createAsyncThunk('balanceLimits/getCustomerBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/customers/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getCustomerBalanceLimitsFilters = createAsyncThunk('balanceLimits/getCustomerBalanceLimitsFilters', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/customers/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createCustomerBalanceLimits = createAsyncThunk('balanceLimits/createCustomerBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/customers/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const editCustomerBalanceLimits = createAsyncThunk('balanceLimits/editCustomerBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.put(`/balance-limits/customers/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Customers

// ** Suppliers
export const getSupplierBalanceLimits = createAsyncThunk('balanceLimits/getSupplierBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/suppliers/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getSupplierBalanceLimitsFilters = createAsyncThunk('balanceLimits/getSupplierBalanceLimitsFilters', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/suppliers/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createSupplierBalanceLimits = createAsyncThunk('balanceLimits/createSupplierBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.post(`/balance-limits/suppliers/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const editSupplierBalanceLimits = createAsyncThunk('balanceLimits/editSupplierBalanceLimits', async (
    {
        id,
        params
    }) => {
    try {
        const response = await axios.put(`/balance-limits/suppliers/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Suppliers

const initialState = {
    loadingBalances: false,
    loadingFilters: false,
    balances: [],
    filters: [],
    selectedValueSize: 0
}

export const balanceLimitsSlice = createSlice({
    name: 'balanceLimits',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserBalanceLimits.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getUserBalanceLimits.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getUserBalanceLimitsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getUserBalanceLimitsFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
            })

            .addCase(getCustomerBalanceLimits.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getCustomerBalanceLimits.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getCustomerBalanceLimitsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCustomerBalanceLimitsFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
            })

            .addCase(getSupplierBalanceLimits.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getSupplierBalanceLimits.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getSupplierBalanceLimitsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSupplierBalanceLimitsFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
            })
    }
})

export const {} = balanceLimitsSlice.actions

export default balanceLimitsSlice.reducer
