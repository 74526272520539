import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getMyBankAccounts = createAsyncThunk('myBankAccounts/getMyBankAccounts', async (params) => {
    try {
        const response = await axios.post('/my-bank-accounts/list', params)
        return {
            myBankAccounts: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getMyBankAccount = createAsyncThunk('myBankAccounts/getMyBankAccount', async (id) => {
    try {
        const response = await axios.get(`/my-bank-accounts/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createMyBankAccount = createAsyncThunk('myBankAccounts/createMyBankAccount', async (params) => {
    try {
        const response = await axios.post(`/my-bank-accounts`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editMyBankAccount = createAsyncThunk('myBankAccounts/editMyBankAccount', async (params) => {
    try {
        const response = await axios.put(`/my-bank-accounts`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteMyBankAccount = createAsyncThunk('myBankAccounts/deleteMyBankAccount', async (id) => {
    try {
        const response = await axios.delete(`/my-bank-accounts/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getMyBankAccountFilters = createAsyncThunk('myBankAccounts/getMyBankAccountFilters', async (params) => {
    try {
        const response = await axios.post(`/my-bank-accounts/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingMyBankAccounts: false,
    myBankAccounts: [],
    filters: [],
    total: 0,
    loadingMyBankAccount: false,
    loadingMyBankAccountsFilters: false,
    selectedMyBankAccount: null
}

export const myBankAccountsSlice = createSlice({
    name: 'myBankAccounts',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getMyBankAccounts.pending, (state) => {
                state.loadingMyBankAccounts = true
            })
            .addCase(getMyBankAccounts.fulfilled, (state, action) => {
                state.myBankAccounts = action?.payload?.myBankAccounts
                state.total = action?.payload?.total
                state.loadingMyBankAccounts = false
            })
            .addCase(getMyBankAccount.pending, (state) => {
                state.loadingMyBankAccount = true
            })
            .addCase(getMyBankAccount.fulfilled, (state, action) => {
                state.selectedMyBankAccount = action?.payload
                state.loadingMyBankAccount = false
            })
            .addCase(getMyBankAccountFilters.pending, (state) => {
                state.loadingMyBankAccountsFilters = true
            })
            .addCase(getMyBankAccountFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingMyBankAccountsFilters = false
            })
    }
})

export const {} = myBankAccountsSlice.actions

export default myBankAccountsSlice.reducer
