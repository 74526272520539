import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getBankAccounts = createAsyncThunk('bankAccounts/getBankAccounts', async (params) => {
    try {
        const response = await axios.post('/bank-accounts/list', params)
        return {
            bankAccounts: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getBankAccount = createAsyncThunk('bankAccounts/getBankAccount', async (id) => {
    try {
        const response = await axios.get(`/bank-accounts/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createBankAccount = createAsyncThunk('bankAccounts/createBank', async (params) => {
    try {
        const response = await axios.post(`/bank-accounts`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editBankAccount = createAsyncThunk('bankAccounts/editBankAccount', async (params) => {
    try {
        const response = await axios.put(`/bank-accounts`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteBankAccount = createAsyncThunk('bankAccounts/deleteBankAccount', async (id) => {
    try {
        const response = await axios.delete(`/bank-accounts/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getBankAccountFilters = createAsyncThunk('bankAccounts/getBankAccountFilters', async (params) => {
    try {
        const response = await axios.post(`/bank-accounts/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingBankAccounts: false,
    bankAccounts: [],
    filters: [],
    total: 0,
    loadingBankAccount: false,
    loadingBankAccountsFilters: false,
    selectedBankAccount: null,
    selectedValueSize: 0
}

export const bankAccountsSlice = createSlice({
    name: 'bankAccounts',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBankAccounts.pending, (state) => {
                state.loadingBankAccounts = true
            })
            .addCase(getBankAccounts.fulfilled, (state, action) => {
                state.bankAccounts = action?.payload?.bankAccounts
                state.total = action?.payload?.total
                state.loadingBankAccounts = false
            })
            .addCase(getBankAccount.pending, (state) => {
                state.loadingBankAccount = true
            })
            .addCase(getBankAccount.fulfilled, (state, action) => {
                state.selectedBankAccount = action?.payload
                state.loadingBankAccount = false
            })
            .addCase(getBankAccountFilters.pending, (state) => {
                state.loadingBankAccountsFilters = true
            })
            .addCase(getBankAccountFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingBankAccountsFilters = false
            })
    }
})

export const {} = bankAccountsSlice.actions

export default bankAccountsSlice.reducer
