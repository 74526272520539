import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getTaxes = createAsyncThunk('taxes/getTaxes', async () => {
    try {
        const response = await axios.get('/taxes')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getTax = createAsyncThunk('taxes/getTax', async (id) => {
    try {
        const response = await axios.get(`/taxes/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createTax = createAsyncThunk('taxes/createTax', async (params) => {
    try {
        const response = await axios.post('/taxes', params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editTax = createAsyncThunk('taxes/editTax', async (params) => {
    try {
        const response = await axios.put(`/taxes`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteTax = createAsyncThunk('taxes/deleteTax', async (id) => {
    try {
        const response = await axios.delete(`/taxes/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    taxes: [],
    loadingTaxes: false,
    loadingTax: false,
    selectedTax: null
}

export const taxesSlice = createSlice({
    name: 'taxes',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTaxes.pending, (state) => {
                state.loadingTaxes = true
            })
            .addCase(getTaxes.fulfilled, (state, action) => {
                state.taxes = action?.payload
                state.loadingTaxes = false
            })

            .addCase(getTax.pending, (state) => {
                state.loadingTax = true
            })
            .addCase(getTax.fulfilled, (state, action) => {
                state.selectedTax = action?.payload
                state.loadingTax = false
            })
    }
})

export const {} = taxesSlice.actions

export default taxesSlice.reducer
