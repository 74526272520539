import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'

// ** Actions
import {initialLanguage} from '@store/layout'

i18n
    // Enables the i18next backend
    .use(Backend)
    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
        lng: initialLanguage(),
        backend: {
            /* translation file path */
            loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json`
        },
        fallbackLng: 'tr',
        debug: false,
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        }
    })

export default i18n
