import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getSuppliers = createAsyncThunk('suppliers/getSuppliers', async (params) => {
    try {
        const response = await axios.post('/suppliers/list', params)
        return {
            suppliers: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getSupplier = createAsyncThunk('suppliers/getSupplier', async (id) => {
    try {
        const response = await axios.get(`/suppliers/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createSupplier = createAsyncThunk('suppliers/createSupplier', async (supplier) => {
    try {
        const response = await axios.post('/suppliers', supplier)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editSupplier = createAsyncThunk('suppliers/editSupplier', async (supplier) => {
    try {
        const response = await axios.put(`/suppliers`, supplier)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteSupplier = createAsyncThunk('suppliers/deleteSuppliers', async (id) => {
    try {
        const response = await axios.delete(`/suppliers/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getSuppliersFilters = createAsyncThunk('suppliers/getSuppliersFilters', async (params) => {
    try {
        const response = await axios.post(`/suppliers/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const setSupplierCategories = createAsyncThunk('suppliers/setSupplierCategories', async ({supplierId, categoryId}) => {
    try {
        const response = await axios.post(`/suppliers/${supplierId}/categories/${categoryId}`)
        return response.data
    } catch (e) {
        console.error(e)
    }
})

export const deleteSupplierCategories = createAsyncThunk('suppliers/deleteSupplierCategories', async ({supplierId, categoryId}) => {
    try {
        const response = await axios.delete(`/suppliers/${supplierId}/categories/${categoryId}`)
        return response.data
    } catch (e) {
        console.error(e)
    }
})

export const getSupplierCategories = createAsyncThunk('suppliers/getSupplierCategories', async ({supplierId}) => {
    try {
        const response = await axios.get(`/suppliers/${supplierId}/categories`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getSupplierBalance = createAsyncThunk('suppliers/getSupplierBalance', async (id) => {
    try {
        const response = await axios.get(`/balances/suppliers/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getDashboardSupplier = createAsyncThunk('suppliers/getDashboardSupplier', async () => {
    try {
        const response = await axios.get(`/dashboard/supplierCounts`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    suppliers: [],
    appliedFilters: [],
    selectedSupplier: null,
    filters: [],
    loadingSuppliers: false,
    loadingFilters: false,
    loadingSupplier: false,
    balances: [],
    loadingSupplierBalance: false,
    loadingSupplierCategories: false,
    selectedValueSize: 0,
    loadingDashboardSupplier: false,
    dashboardSupplier: []
}

export const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSuppliers.pending, (state) => {
                state.loadingSuppliers = true
            })
            .addCase(getSuppliers.fulfilled, (state, action) => {
                state.loadingSuppliers = false
                state.suppliers = action?.payload?.suppliers
                state.total = action?.payload?.total
            })

            .addCase(getSupplier.pending, (state) => {
                state.loadingSupplier = true
            })
            .addCase(getSupplier.fulfilled, (state, action) => {
                state.selectedSupplier = action.payload
                state.loadingSupplier = false
            })

            .addCase(getSuppliersFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSuppliersFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.filters
            })

            .addCase(getSupplierCategories.pending, (state) => {
                state.loadingSupplierCategories = true
            })
            .addCase(getSupplierCategories.fulfilled, (state, action) => {
                state.loadingSupplierCategories = false
                state.supplierCategories = action.payload
            })

            .addCase(getSupplierBalance.pending, (state) => {
                state.loadingSupplierBalance = true
            })
            .addCase(getSupplierBalance.fulfilled, (state, action) => {
                state.loadingSupplierBalance = false
                state.balances = action.payload
            })

            .addCase(getDashboardSupplier.pending, (state) => {
                state.loadingDashboardSupplier = true
            })
            .addCase(getDashboardSupplier.fulfilled, (state, action) => {
                state.dashboardSupplier = action.payload
                state.loadingDashboardSupplier = false
            })
    }
})

export const {} = suppliersSlice.actions

export default suppliersSlice.reducer
