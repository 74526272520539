// You can customize the template with the help of this file
// Template config options

const themeConfig = {
    app: {
        appName: process.env.REACT_APP_NAME,
        appLogoImage: require(`@src/assets/images/logo/${process.env.REACT_APP_LOGO_IMAGE_NAME}.svg`).default,
        appLogoImageText: require('@src/assets/images/logo/logo-text.svg').default,
        appLogoIcon: require(`@src/assets/images/logo/${process.env.REACT_APP_LOGO_IMAGE_NAME}.svg`).default,
        appCompanyLogo: require('@src/assets/images/logo/doallon-text.svg').default,
        companyUrl: 'https://www.doallon.com',
        ulaxysUrl: 'https://www.ulaxys.com',
        loginBackGroundImage: require('@src/assets/images/login/logistics.png').default,
        deliveryReceiptImage: require(`@src/assets/images/logo/${process.env.REACT_APP_LOGO_IMAGE_FULL}.svg`).default
    },
    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: 'vertical', // vertical, horizontal
        contentWidth: 'boxed', // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: false
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: 'white' // BS color options [primary, success, etc]
        },
        footer: {
            type: 'sticky' // static, sticky, hidden
        },
        customizer: false,
        scrollTop: true, // Enable scroll to top button
        toastPosition: 'top-right',
        colors: { // todo
            primary: '#008cc4',
            secondary: '#82868b'
        }
    }
}

export default themeConfig
