import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../utility/customAxios'

export const getBalances = createAsyncThunk('dashboard/getBalance', async () => {
    try {
        const response = await axios.get(`/balances/my-balances`)
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingBalances: false,
    balances: []
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBalances.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })
    }
})

export const {} = dashboardSlice.actions

export default dashboardSlice.reducer
