import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getProducts = createAsyncThunk('products/getProducts', async (params) => {
    try {
        const response = await axios.post('/products/list', params)
        return {
            products: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

export const getProduct = createAsyncThunk('products/getProduct', async (id) => {
    try {
        const response = await axios.get(`/products/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createProduct = createAsyncThunk('products/createProducts', async (params) => {
    try {
        const response = await axios.post('/products', params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editProduct = createAsyncThunk('products/editProducts', async (params) => {
    try {
        const response = await axios.put(`/products`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteProduct = createAsyncThunk('products/deleteProduct', async (id) => {
    try {
        const response = await axios.delete(`/products/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getProductsFilters = createAsyncThunk('products/getProductFilters', async (params) => {
    try {
        const response = await axios.post(`/products/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

export const getProductUnits = createAsyncThunk('products/getProductUnits', async (params) => {
    const data = []
    try {
        const response = await axios.get(`/productUnits`, params)
        response.data.items.map(item => data.push({value: item.code, label: item.name}))
        return data
    } catch (e) {
        console.error(e)
    }
})

export const getProductSearch = createAsyncThunk('products/getProductSearch', async (params) => {
    const data = []
    try {
        const response = await axios.get(`/products`, params)
        response.data.items.map(item => data.push({value: item.id, label: item.code}))
        return data
    } catch (e) {
        console.error(e)
    }
})

export const getProductTaxes = createAsyncThunk('products/getProductTaxes', async (params) => {
    const data = []
    try {
        const response = await axios.get(`/taxes`, params)
        response.data.items.map(item => data.push({value: item.id, label: `${item.name}`}))
        return data
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    products: [],
    loadingProducts: false,
    selectedProduct: null,
    loadingProduct: null,
    appliedFilters: [],
    filters: [],
    loadingFilters: false,
    productUnits: [],
    productSearch: [],
    loadingProductTaxes: false,
    productTaxes: [],
    loadingProductSearch: false
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loadingProducts = true
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.products = action?.payload?.products
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingProducts = false
            })

            .addCase(getProduct.pending, (state) => {
                state.loadingProduct = true
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.selectedProduct = action?.payload
                state.loadingProduct = false
            })

            .addCase(getProductsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getProductsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload
                state.loadingFilters = false
            })

            .addCase(getProductUnits.fulfilled, (state, action) => {
                state.productUnits = action?.payload
            })
            .addCase(getProductSearch.pending, (state) => {
                state.loadingProductSearch = true
            })

            .addCase(getProductSearch.fulfilled, (state, action) => {
                state.productSearch = action?.payload
                state.loadingProductSearch = false
            })

            .addCase(getProductTaxes.pending, (state) => {
                state.loadingProductTaxes = true
            })
            .addCase(getProductTaxes.fulfilled, (state, action) => {
                state.productTaxes = action?.payload
                state.loadingProductTaxes = false
            })
    }
})

export const {} = productsSlice.actions

export default productsSlice.reducer
