import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getProperties = createAsyncThunk('properties/getProducts', async (params) => {
    try {
        const response = await axios.post('/properties/list', params)
        return {
            properties: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getProperty = createAsyncThunk('properties/getProperty', async (id) => {
    try {
        const response = await axios.get(`/properties/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createProperties = createAsyncThunk('properties/createProperties', async (params) => {
    try {
        const response = await axios.post('/properties', params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editProperty = createAsyncThunk('properties/editProperties', async (params) => {
    try {
        const response = await axios.put(`/properties`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteProperty = createAsyncThunk('properties/deleteProperty', async (id) => {
    try {
        const response = await axios.delete(`/properties/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getPropertiesFilters = createAsyncThunk('properties/getPropertiesFilters', async (params) => {
    try {
        const response = await axios.post(`/properties/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    properties: [],
    loadingProperties: false,
    selectedProperty: null,
    loadingProperty: false,
    appliedFilters: [],
    loadingFilters: false,
    filters: [],
    selectedValueSize: 0
}

export const propertiesSlice = createSlice({
    name: 'properties',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getProperties.pending, (state) => {
                state.loadingProperties = true
            })
            .addCase(getProperties.fulfilled, (state, action) => {
                state.properties = action?.payload?.properties
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingProperties = false
            })

            .addCase(getProperty.pending, (state) => {
                state.loadingProperty = true
            })
            .addCase(getProperty.fulfilled, (state, action) => {
                state.selectedProperty = action?.payload
                state.loadingProperty = false
            })

            .addCase(getPropertiesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getPropertiesFilters.fulfilled, (state, action) => {
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.filters
                state.loadingFilters = false
            })
    }
})

export const {} = propertiesSlice.actions

export default propertiesSlice.reducer
