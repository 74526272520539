import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../utility/customAxios'

export const getNotifications = createAsyncThunk('notifications/getNotifications', async (params) => {
    try {
        const response = await axios.post('/notifications/list', params)
        return {
            notifications: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getNotification = createAsyncThunk('notifications/getNotification', async (id) => {
    try {
        const response = await axios.get(`/notifications/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getUnReadNotification = createAsyncThunk('notifications/getUnReadNotification', async () => {
    try {
        const response = await axios.get(`/notifications`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        unReadNotifications: [],
        selectedNotification: null,
        loadingNotifications: false,
        loadingNotification: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.loadingNotifications = true
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.loadingNotifications = false
                state.notifications = action?.payload?.notifications
                state.total = action?.payload?.total
            })

            .addCase(getNotification.pending, (state) => {
                state.loadingNotification = true
            })
            .addCase(getNotification.fulfilled, (state, action) => {
                state.loadingNotification = false
                state.selectedNotification = action?.payload
            })

            .addCase(getUnReadNotification.pending, (state) => {
                state.loadingUnReadNotification = true
            })
            .addCase(getUnReadNotification.fulfilled, (state, action) => {
                state.loadingUnReadNotification = false
                state.unReadNotifications = action?.payload
            })
    }
})

export const {} = notificationSlice.actions

export default notificationSlice.reducer
