import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../utility/customAxios'

export const getModules = createAsyncThunk('settings/getModules', async () => {
    try {
        const response = await axios.get('/settings/modules')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editModule = createAsyncThunk('settings/editModules', async (modules) => {
    try {
        const response = await axios.put(`/settings/modules`, modules)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getNotification = createAsyncThunk('settings/getNotification', async () => {
    try {
        const response = await axios.get('/settings/notification')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editNotification = createAsyncThunk('settings/editModules', async (notification) => {
    try {
        const response = await axios.put(`/settings/notification`, notification)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        modules: [],
        notification: [],
        loadingModules: false,
        loadingNotification: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getModules.pending, (state) => {
                state.loadingModules = true
            })
            .addCase(getModules.fulfilled, (state, action) => {
                state.modules = action.payload
                state.loadingModules = false
            })
            .addCase(getNotification.pending, (state) => {
                state.loadingNotification = true
            })
            .addCase(getNotification.fulfilled, (state, action) => {
                state.notification = action.payload
                state.loadingNotification = false
            })
    }
})

export const {} = settingsSlice.actions

export default settingsSlice.reducer
