import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getDepartments = createAsyncThunk('departments/getDepartments', async (params) => {
    try {
        const response = await axios.post('/departments/list', params)
        return {
            departments: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getDepartmentsSearch = createAsyncThunk('departments/getDepartmentsSearch', async (params) => {
    const data = []
    try {
        const response = await axios.get('/departments', params)
        response.data.items.map(item => data.push({value: item.id, label: item.name}))
        return data
    } catch (e) {
        console.error(e)
    }
})

export const getDepartment = createAsyncThunk('departments/getDepartment', async (departmentId) => {
    try {
        const response = await axios.get(`/departments/${departmentId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createDepartment = createAsyncThunk('departments/createDepartment', async (department) => {
    try {
        const response = await axios.post('/departments', department)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editDepartment = createAsyncThunk('departments/editDepartment', async (department) => {
    try {
        const response = await axios.put(`/departments`, department)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteDepartment = createAsyncThunk('departments/deleteDepartment', async (id) => {
    try {
        const response = await axios.delete(`/departments/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const setDepartmentsUser = createAsyncThunk('departments/setDepartmentsUser', async (params) => {
    try {
        const response = await axios.post(`/users/${params?.userId}/departments`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const deleteDepartmentsUsers = createAsyncThunk('departments/deleteDepartmentsUsers', async ({id, userId}) => {
    try {
        const response = await axios.delete(`/users/${userId}/departments/${id}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const getDepartmentsFilters = createAsyncThunk('departments/getDepartmentsFilters', async (params) => {
    try {
        const response = await axios.post(`/departments/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getDepartmentUserFilters = createAsyncThunk('departments/getDepartmentUserFilter', async (params) => {
    try {
        const response = await axios.post(`/users/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getDepartmentUsers = createAsyncThunk('departments/getDepartmentUsers', async ({departmentId, users}) => {
    try {
        const response = await axios.post(`/departments/${departmentId}/users/list`, users)
        return {
            departmentUsers: response?.data?.items,
            departmentUsersTotal: response?.data?.size,
            departmentUsersAppliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

export const getDepartmentBalance = createAsyncThunk('departments/getDepartmentBalance', async (id) => {
    try {
        const response = await axios.get(`/balances/departments/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    departments: [],
    appliedFilters: [],
    departmentUsers: [],
    filters: [],
    userFilters: [],
    departmentsSearch: [],
    departmentUsersAppliedFilters: [],
    departmentUsersTotal: null,
    selectedDepartment: null,
    loadingDepartments: false,
    loadingFilters: false,
    loadingDepartment: false,
    loadingUsers: false,
    loadingUserFilters: false,
    balances: [],
    loadingDepartmentBalance: false,
    selectedUserValueSize: 0
}

export const departmentsSlice = createSlice({
    name: 'departments',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDepartments.pending, (state) => {
                state.loadingDepartments = true
            })
            .addCase(getDepartments.fulfilled, (state, action) => {
                state.departments = action?.payload?.departments
                state.total = action?.payload?.total
                state.loadingDepartments = false
            })

            .addCase(getDepartment.pending, (state) => {
                state.loadingDepartment = true
            })
            .addCase(getDepartment.fulfilled, (state, action) => {
                state.selectedDepartment = action.payload
                state.loadingDepartment = false
            })

            .addCase(getDepartmentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getDepartmentsFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.filters
            })

            .addCase(getDepartmentUsers.pending, (state) => {
                state.loadingUsers = true
            })
            .addCase(getDepartmentUsers.fulfilled, (state, action) => {
                state.loadingUsers = false
                state.departmentUsers = action?.payload?.departmentUsers
                state.departmentUsersTotal = action?.payload?.departmentUsersTotal
                state.departmentUsersAppliedFilters = action?.payload?.departmentUsersAppliedFilters
            })

            .addCase(getDepartmentsSearch.pending, (state) => {
                state.loadingDepartmentSearch = true
            })
            .addCase(getDepartmentsSearch.fulfilled, (state, action) => {
                state.loadingDepartmentSearch = false
                state.departmentsSearch = action.payload
            })

            .addCase(getDepartmentBalance.pending, (state) => {
                state.loadingDepartmentBalance = true
            })
            .addCase(getDepartmentBalance.fulfilled, (state, action) => {
                state.loadingDepartmentBalance = false
                state.balances = action.payload
            })

            .addCase(getDepartmentUserFilters.pending, (state) => {
                state.loadingUserFilters = true
            })
            .addCase(getDepartmentUserFilters.fulfilled, (state, action) => {
                state.loadingUserFilters = false
                state.selectedUserValueSize = action?.payload?.selectedUserValueSize
                state.userFilters = action?.payload?.filters
            })
    }
})

export const {} = departmentsSlice.actions

export default departmentsSlice.reducer
