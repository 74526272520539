import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

/* System Documents */
export const getSystemDocument = createAsyncThunk('documents/getSystemDocument', async (params) => {
    try {
        const response = await axios.get(`/clouds/system-documents/${params?.documentId}`)
        return {
            selectedDocument: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getSystemDocuments = createAsyncThunk('documents/getSystemDocuments', async (params) => {
    try {
        const response = await axios.post(`/clouds/system-documents/categories/${params?.documentCategoryId}`, params)
        return {
            documents: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getSystemDocumentsFilters = createAsyncThunk('documents/getSystemDocumentsFilters', async (params) => {
    try {
        const response = await axios.post(`/clouds/system-documents/categories/${params?.documentCategoryId}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

/* My Documents */
export const getMyDocument = createAsyncThunk('documents/getMyDocument', async (documentId) => {
    try {
        const response = await axios.get(`/clouds/my-documents/${documentId}`)
        return {
            selectedDocument: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getMyDocuments = createAsyncThunk('documents/getMyDocuments', async (params) => {
    try {
        const response = await axios.post(`/clouds/my-documents/categories/${params?.documentCategoryId}`, params)
        return {
            documents: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getMyDocumentsFilters = createAsyncThunk('documents/getMyDocumentsFilters', async (params) => {
    try {
        const response = await axios.post(`/clouds/my-documents/categories/${params?.documentCategoryId}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

/* Users Documents */
export const getUsersDocument = createAsyncThunk('documents/getUsersDocument', async (params) => {
    try {
        const response = await axios.get(`/clouds/users/${params?.ownerId}/documents/${params?.documentId}`)
        return {
            selectedDocument: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getUsersDocuments = createAsyncThunk('documents/getUsersDocuments', async (params) => {
    try {
        const response = await axios.post(`/clouds/users/${params?.ownerId}/categories/${params?.documentCategoryId}`, params)
        return {
            documents: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getUsersDocumentsFilters = createAsyncThunk('documents/getUsersDocumentsFilters', async (params) => {
    try {
        const response = await axios.post(`/clouds/users/${params?.ownerId}/categories/${params?.documentCategoryId}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

/* Customers Documents */
export const getCustomersDocument = createAsyncThunk('documents/getCustomersDocument', async (params) => {
    try {
        const response = await axios.get(`/clouds/customers/${params?.ownerId}/documents/${params?.documentId}`)
        return {
            selectedDocument: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getCustomersDocuments = createAsyncThunk('documents/getCustomersDocuments', async (params) => {
    try {
        const response = await axios.post(`/clouds/customers/${params?.ownerId}/categories/${params?.documentCategoryId}`, params)
        return {
            documents: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getCustomersDocumentsFilters = createAsyncThunk('documents/getCustomersDocumentsFilters', async (params) => {
    try {
        const response = await axios.post(`/clouds/customers/${params?.ownerId}/categories/${params?.documentCategoryId}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

/* Suppliers Documents */
export const getSuppliersDocument = createAsyncThunk('documents/getSuppliersDocument', async (params) => {
    try {
        const response = await axios.get(`/clouds/suppliers/${params?.ownerId}/documents/${params?.documentId}`)
        return {
            selectedDocument: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getSuppliersDocuments = createAsyncThunk('documents/getSuppliersDocuments', async (params) => {
    try {
        const response = await axios.post(`/clouds/suppliers/${params?.ownerId}/categories/${params?.documentCategoryId}`, params)
        return {
            documents: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getSuppliersDocumentsFilters = createAsyncThunk('documents/getSuppliersDocumentsFilters', async (params) => {
    try {
        const response = await axios.post(`/clouds/suppliers/${params?.ownerId}/categories/${params?.documentCategoryId}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

/* Departments Documents */
export const getDepartmentsDocument = createAsyncThunk('documents/getDepartmentsDocument', async (params) => {
    try {
        const response = await axios.get(`/clouds/departments/${params?.ownerId}/documents/${params?.documentId}`)
        return {
            selectedDocument: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDepartmentsDocuments = createAsyncThunk('documents/getDepartmentsDocuments', async (params) => {
    try {
        const response = await axios.post(`/clouds/departments/${params?.ownerId}/categories/${params?.documentCategoryId}`, params)
        return {
            documents: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data?.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDepartmentsDocumentsFilters = createAsyncThunk('documents/getDepartmentsDocumentsFilters', async (params) => {
    try {
        const response = await axios.post(`/clouds/departments/${params?.ownerId}/categories/${params?.documentCategoryId}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

/* Categories */
export const getDocumentCategories = createAsyncThunk('documents/getDocumentCategories', async (params) => {
    try {
        const response = await axios.post(`/document-categories/list`, params)
        return {
            categories: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoriesFilters = createAsyncThunk('documents/getDocumentCategoriesFilters', async (params) => {
    try {
        const response = await axios.post(`/document-categories/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategory = createAsyncThunk('documents/getDocumentCategory', async (documentCategoryId) => {
    try {
        const response = await axios.get(`/document-categories/${documentCategoryId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoriesSearch = createAsyncThunk('documents/getDocumentCategoriesSearch', async (params) => {
    try {
        const response = await axios.get(`/document-categories`, params)
        return {
            categoriesSearch: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const createDocumentCategory = createAsyncThunk('documents/createDocumentCategory', async (params) => {
    try {
        const response = await axios.post('/document-categories', params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocumentCategory = createAsyncThunk('documents/deleteDocumentCategory', async (documentCategoryId) => {
    try {
        const response = await axios.delete(`/document-categories/${documentCategoryId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

/* Documents */
export const createDocument = createAsyncThunk('documents/createDocument', async (params) => {
    try {
        const response = await axios.post('/documents', params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const updateDocument = createAsyncThunk('documents/updateDocument', async (params) => {
    try {
        const response = await axios.put('/documents', params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocument = createAsyncThunk('documents/deleteDocument', async (documentId) => {
    try {
        const response = await axios.delete(`/documents/${documentId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const createDocumentProperties = createAsyncThunk('documents/createDocumentProperties', async (params) => {
    try {
        const response = await axios.post(`/documents/${params?.documentId}/properties`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocumentProperties = createAsyncThunk('documents/deleteDocumentProperties', async (params) => {
    try {
        const response = await axios.delete(`/documents/${params?.documentId}/properties/${params?.propertyId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

/* DocumentProperties */
export const getDocumentProperties = createAsyncThunk('documents/getDocumentProperties', async (documentId) => {
    try {
        const response = await axios.post(`/documents/${documentId}/properties`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

/* DocumentCategoryProperties */
export const createDocumentCategoryProperties = createAsyncThunk('documents/createDocumentCategoryProperties', async (params) => {
    try {
        const response = await axios.post(`/document-categories/${params?.documentCategoryId}/properties/${params?.propertyId}`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocumentCategoryProperties = createAsyncThunk('documents/deleteDocumentCategoryProperties', async (params) => {
    try {
        const response = await axios.delete(`/document-categories/${params?.documentCategoryId}/properties/${params?.propertyId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoryProperties = createAsyncThunk('documents/getDocumentCategoryProperties', async (params) => {
    try {
        const response = await axios.get(`/document-categories/${params?.documentCategoryId}/properties`)
        return {
            properties: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

/* Breadcrumbs */
export const getBreadcrumbs = createAsyncThunk('documents/getBreadcrumbs', async (params) => {
    try {
        const response = await axios.get(`/clouds/breadcrumbs`, {
            params
        })
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    documents: [],
    selectedDocument: null,
    selectedCategory: null,
    appliedFilters: [],
    filters: [],
    documentsFilters: [],
    categories: [],
    categoriesSearch: [],
    selectedValueSize: 0,
    selectedValueSizeCategory: 0,
    loadingDocuments: null,
    loadingDocument: false,
    loadingFilters: false,
    loadingCategories: false,
    loadingCategory: false,
    loadingCategoryFilters: false,
    breadcrumbs: [],
    loadingBreadcrumbs: false,
    properties: [],
    loadingDocumentProperties: false
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            /* System Documents */
            .addCase(getSystemDocuments.pending, (state) => {
                state.loadingDocuments = true
            })
            .addCase(getSystemDocuments.fulfilled, (state, action) => {
                state.documents = action?.payload?.documents
                state.total = action?.payload?.total
                state.loadingDocuments = false
            })

            .addCase(getSystemDocumentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSystemDocumentsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getSystemDocument.pending, (state) => {
                state.loadingDocument = true
            })
            .addCase(getSystemDocument.fulfilled, (state, action) => {
                state.selectedDocument = action?.payload?.selectedDocument
                state.loadingDocument = false
            })

            /* My Documents */
            .addCase(getMyDocuments.pending, (state) => {
                state.loadingDocuments = true
            })
            .addCase(getMyDocuments.fulfilled, (state, action) => {
                state.documents = action?.payload?.documents
                state.total = action?.payload?.total
                state.loadingDocuments = false
            })

            .addCase(getMyDocumentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getMyDocumentsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getMyDocument.pending, (state) => {
                state.loadingDocument = true
            })
            .addCase(getMyDocument.fulfilled, (state, action) => {
                state.selectedDocument = action?.payload?.selectedDocument
                state.loadingDocument = false
            })

            /* User Documents */
            .addCase(getUsersDocuments.pending, (state) => {
                state.loadingDocuments = true
            })
            .addCase(getUsersDocuments.fulfilled, (state, action) => {
                state.documents = action?.payload?.documents
                state.total = action?.payload?.total
                state.loadingDocuments = false
            })

            .addCase(getUsersDocumentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getUsersDocumentsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getUsersDocument.pending, (state) => {
                state.loadingDocument = true
            })
            .addCase(getUsersDocument.fulfilled, (state, action) => {
                state.selectedDocument = action?.payload?.selectedDocument
                state.loadingDocument = false
            })

            /* Customer Documents */
            .addCase(getCustomersDocuments.pending, (state) => {
                state.loadingDocuments = true
            })
            .addCase(getCustomersDocuments.fulfilled, (state, action) => {
                state.documents = action?.payload?.documents
                state.total = action?.payload?.total
                state.loadingDocuments = false
            })

            .addCase(getCustomersDocumentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCustomersDocumentsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getCustomersDocument.pending, (state) => {
                state.loadingDocument = true
            })
            .addCase(getCustomersDocument.fulfilled, (state, action) => {
                state.selectedDocument = action?.payload?.selectedDocument
                state.loadingDocument = false
            })

            /* Supplier Documents */
            .addCase(getSuppliersDocuments.pending, (state) => {
                state.loadingDocuments = true
            })
            .addCase(getSuppliersDocuments.fulfilled, (state, action) => {
                state.documents = action?.payload?.documents
                state.total = action?.payload?.total
                state.loadingDocuments = false
            })

            .addCase(getSuppliersDocumentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSuppliersDocumentsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getSuppliersDocument.pending, (state) => {
                state.loadingDocument = true
            })
            .addCase(getSuppliersDocument.fulfilled, (state, action) => {
                state.selectedDocument = action?.payload?.selectedDocument
                state.loadingDocument = false
            })

            /* Departments Documents */
            .addCase(getDepartmentsDocuments.pending, (state) => {
                state.loadingDocuments = true
            })
            .addCase(getDepartmentsDocuments.fulfilled, (state, action) => {
                state.documents = action?.payload?.documents
                state.total = action?.payload?.total
                state.loadingDocuments = false
            })

            .addCase(getDepartmentsDocumentsFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getDepartmentsDocumentsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getDepartmentsDocument.pending, (state) => {
                state.loadingDocument = true
            })
            .addCase(getDepartmentsDocument.fulfilled, (state, action) => {
                state.selectedDocument = action?.payload?.selectedDocument
                state.loadingDocument = false
            })

            /* Categories */
            .addCase(getDocumentCategories.pending, (state) => {
                state.loadingCategories = true
            })
            .addCase(getDocumentCategories.fulfilled, (state, action) => {
                state.categories = action?.payload?.categories?.map((item) => ({
                    name: item?.title,
                    type: 'folder',
                    id: item?.id,
                    isCategory: true
                }))
                state.total = action?.payload?.total
                state.loadingCategories = false
            })

            .addCase(getDocumentCategoriesFilters.pending, (state) => {
                state.loadingCategoryFilters = true
            })
            .addCase(getDocumentCategoriesFilters.fulfilled, (state, action) => {
                state.loadingCategoryFilters = false
                state.selectedValueSizeCategory = action?.payload?.selectedValueSize
                state.documentsFilters = action?.payload?.filters
            })

            .addCase(getDocumentCategory.pending, (state) => {
                state.loadingCategory = true
            })
            .addCase(getDocumentCategory.fulfilled, (state, action) => {
                state.selectedCategory = action.payload
                state.loadingCategory = false
            })

            .addCase(getBreadcrumbs.pending, (state) => {
                state.loadingBreadcrumbs = true
            })
            .addCase(getBreadcrumbs.fulfilled, (state, action) => {
                state.breadcrumbs = action?.payload
                state.loadingBreadcrumbs = false
            })

            .addCase(getDocumentProperties.pending, (state) => {
                state.loadingDocumentProperties = true
            })
            .addCase(getDocumentProperties.fulfilled, (state, action) => {
                state.properties = action?.payload
                state.loadingDocumentProperties = false
            })

            .addCase(getDocumentCategoryProperties.pending, (state) => {
                state.loadingDocumentProperties = true
            })
            .addCase(getDocumentCategoryProperties.fulfilled, (state, action) => {
                state.properties = action?.payload?.properties
                state.total = action?.payload?.total
                state.loadingDocumentProperties = false
            })
    }
})

export const {} = documentsSlice.actions

export default documentsSlice.reducer
