import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

// ** Warehouses
export const getWarehouses = createAsyncThunk('warehouse/getWarehouses', async (params) => {
    try {
        const response = await axios.post('/warehouses/list', params)
        return {
            warehouses: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getWarehouse = createAsyncThunk('warehouse/getWarehouse', async (id) => {
    try {
        const response = await axios.get(`/warehouses/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createWarehouse = createAsyncThunk('warehouse/createWarehouse', async (params) => {
    try {
        const response = await axios.post(`/warehouses`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editWarehouse = createAsyncThunk('warehouse/editWarehouse', async (params) => {
    try {
        const response = await axios.put(`/warehouses`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteWarehouse = createAsyncThunk('warehouse/deleteWarehouse', async (id) => {
    try {
        const response = await axios.delete(`/warehouses/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getWarehousesFilters = createAsyncThunk('warehouse/getCheckoutsFilters', async (params) => {
    try {
        const response = await axios.post(`/warehouses/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

// ** Warehouse Users
export const getWarehouseUsers = createAsyncThunk('warehouse/getWarehouseUsers', async (id, params) => {
    try {
        const response = await axios.post(`/warehouses/${id}/users/list`, params)
        return {
            warehouseUsers: response?.data?.items,
            totalUsers: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const createUserWarehouse = createAsyncThunk('warehouse/createUserWarehouse', async ({id, userId}) => {
    try {
        const response = await axios.post(`/warehouses/${id}/users/${userId}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const deleteUserWarehouse = createAsyncThunk('warehouse/deleteUserWarehouse', async ({id, userId}) => {
    try {
        const response = await axios.delete(`/warehouses/${id}/users/${userId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getWarehouseUsersFilters = createAsyncThunk('warehouse/getWarehouseUsersFilters', async (id, params) => {
    try {
        const response = await axios.post(`/warehouses/${id}/users/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

// ** Warehouse Departments
export const getWarehouseDepartments = createAsyncThunk('warehouse/getWarehouseDepartments', async (id, params) => {
    try {
        const response = await axios.post(`/warehouses/${id}/departments/list`, params)
        return {
            warehousesDepartments: response?.data?.items,
            totalDepartments: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const createDepartmentWarehouse = createAsyncThunk('warehouse/createDepartmentWarehouse', async ({id, departmentId}) => {
    try {
        const response = await axios.post(`/warehouses/${id}/departments/${departmentId}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const deleteDepartmentWarehouse = createAsyncThunk('warehouse/deleteDepartmentWarehouse', async ({id, departmentId}) => {
    try {
        const response = await axios.delete(`/warehouses/${id}/departments/${departmentId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getWarehouseDepartmentsFilters = createAsyncThunk('warehouse/getWarehouseDepartmentsFilters', async (id, params) => {
    try {
        const response = await axios.post(`/warehouses/${id}/departments/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingWarehouses: false,
    warehouses: [],
    warehousesUsers: [],
    filters: [],
    warehousesUserFilters: [],
    loadingWarehousesUsers: false,
    loadingWarehousesFilters: false,
    loadingWarehousesUserFilters: false,
    total: 0,
    totalUsers: 0,
    totalDepartments: 0,
    loadingWarehouse: false,
    selectedWarehouse: null,
    selectedValueSize: 0,
    warehousesDepartments: [],
    warehousesDepartmentFilters: [],
    loadingWarehousesDepartments: false,
    loadingWarehousesDepartmentFilters: false
}

export const warehousesSlice = createSlice({
    name: 'warehouses',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getWarehouses.pending, (state) => {
                state.loadingWarehouses = true
            })
            .addCase(getWarehouses.fulfilled, (state, action) => {
                state.warehouses = action?.payload?.warehouses
                state.total = action?.payload?.total
                state.loadingWarehouses = false
            })

            .addCase(getWarehouse.pending, (state) => {
                state.loadingWarehouse = true
            })
            .addCase(getWarehouse.fulfilled, (state, action) => {
                state.selectedWarehouse = action?.payload
                state.loadingWarehouse = false
            })

            .addCase(getWarehousesFilters.pending, (state) => {
                state.loadingWarehousesFilters = true
            })
            .addCase(getWarehousesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingWarehousesFilters = false
            })

            .addCase(getWarehouseUsers.pending, (state) => {
                state.loadingWarehouseUsers = true
            })
            .addCase(getWarehouseUsers.fulfilled, (state, action) => {
                state.warehouseUsers = action?.payload?.warehouseUsers
                state.totalUsers = action?.payload?.totalUsers
                state.loadingWarehouseUsers = false
            })

            .addCase(getWarehouseUsersFilters.pending, (state) => {
                state.loadingWarehousesUserFilters = true
            })
            .addCase(getWarehouseUsersFilters.fulfilled, (state, action) => {
                state.warehouseUserFilters = action?.payload
                state.loadingWarehousesUserFilters = false
            })

            .addCase(getWarehouseDepartments.pending, (state) => {
                state.loadingWarehousesDepartments = true
            })
            .addCase(getWarehouseDepartments.fulfilled, (state, action) => {
                state.warehousesDepartments = action?.payload?.warehousesDepartments
                state.totalDepartments = action?.payload?.totalDepartments
                state.loadingWarehousesDepartments = false
            })

            .addCase(getWarehouseDepartmentsFilters.pending, (state) => {
                state.loadingWarehousesDepartmentFilters = true
            })
            .addCase(getWarehouseDepartmentsFilters.fulfilled, (state, action) => {
                state.warehouseDepartmentFilters = action?.payload
                state.loadingWarehousesDepartmentFilters = false
            })
    }
})

export const {} = warehousesSlice.actions

export default warehousesSlice.reducer
