import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getBanks = createAsyncThunk('banks/getBanks', async (params) => {
    try {
        const response = await axios.post('/banks/list', params)
        return {
            banks: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getBank = createAsyncThunk('banks/getBank', async (id) => {
    try {
        const response = await axios.get(`/banks/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createBank = createAsyncThunk('banks/createBank', async (params) => {
    try {
        const response = await axios.post(`/banks`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editBank = createAsyncThunk('banks/editBank', async (params) => {
    try {
        const response = await axios.put(`/banks`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteBank = createAsyncThunk('banks/deleteBank', async (id) => {
    try {
        const response = await axios.delete(`/banks/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getBanksFilters = createAsyncThunk('banks/getBanksFilters', async (params) => {
    try {
        const response = await axios.post(`/banks/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingBanks: false,
    banks: [],
    filters: [],
    total: 0,
    loadingBank: false,
    loadingBanksFilters: false,
    selectedBank: null,
    selectedValueSize: 0
}

export const banksSlice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBanks.pending, (state) => {
                state.loadingBanks = true
            })
            .addCase(getBanks.fulfilled, (state, action) => {
                state.banks = action?.payload?.banks
                state.total = action?.payload?.total
                state.loadingBanks = false
            })
            .addCase(getBank.pending, (state) => {
                state.loadingBank = true
            })
            .addCase(getBank.fulfilled, (state, action) => {
                state.selectedBank = action?.payload
                state.loadingBank = false
            })
            .addCase(getBanksFilters.pending, (state) => {
                state.loadingBanksFilters = true
            })
            .addCase(getBanksFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingBanksFilters = false
            })
    }
})

export const {} = banksSlice.actions

export default banksSlice.reducer
