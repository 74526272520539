import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getUserCodes = createAsyncThunk('userCodes/getUserCodes', async ({userId, params}) => {
    try {
        const response = await axios.post(`/users/${userId}/codes/list`, params)
        return {
            userCodes: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getUserCodesFilters = createAsyncThunk('userCodes/getUserCodesFilters', async ({userId, params}) => {
    try {
        const response = await axios.post(`/users/${userId}/codes/list/filters`, params)
        return response.data
    } catch (e) {
        console.error(e)
    }
})


export const createUserCode = createAsyncThunk('userCodes/createUserCode', async (params) => {
    try {
        const response = await axios.post(`/user-codes/`, params)
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    userCodes: [],
    total: null,
    filters: [],
    loadingUserCodes: false,
    selectedPrefix: null,
    loadingFilters: false,
    selectedValueSize: null
}

export const userCodesSlice = createSlice({
    name: 'userCodes',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserCodes.pending, (state) => {
                state.loadingUserCodes = true
            })
            .addCase(getUserCodes.fulfilled, (state, action) => {
                state.userCodes = action?.payload?.userCodes
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingUserCodes = false
            })

            .addCase(getUserCodesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getUserCodesFilters.fulfilled, (state, action) => {
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.filters = action?.payload?.items
                state.loadingFilters = false
            })
    }
})

export const {} = userCodesSlice.actions

export default userCodesSlice.reducer
