import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

// ** Users
export const getUserBlockedBalances = createAsyncThunk('blockedBalances/getUserBlockedBalances', async ({
                                                                                                            id,
                                                                                                            params
                                                                                                        }) => {
    try {
        const response = await axios.post(`/blocked-balances/users/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getUserBlockedBalancesFilters = createAsyncThunk('blockedBalances/getUserBlockedBalancesFilters', async ({
                                                                                                                          id,
                                                                                                                          params
                                                                                                                      }) => {
    try {
        const response = await axios.post(`/blocked-balances/users/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createUserBlockedBalance = createAsyncThunk('blockedBalances/createBlockedBalance', async ({
                                                                                                            id,
                                                                                                            params
                                                                                                        }) => {
    try {
        const response = await axios.post(`/blocked-balances/users/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Users

// ** Customers
export const getCustomerBlockedBalances = createAsyncThunk('blockedBalances/getCustomerBlockedBalances', async ({
                                                                                                                    id,
                                                                                                                    params
                                                                                                                }) => {
    try {
        const response = await axios.post(`/blocked-balances/customers/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getCustomerBlockedBalancesFilters = createAsyncThunk('blockedBalances/getCustomerBlockedBalancesFilters', async ({
                                                                                                                                  id,
                                                                                                                                  params
                                                                                                                              }) => {
    try {
        const response = await axios.post(`/blocked-balances/customers/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createCustomerBlockedBalance = createAsyncThunk('blockedBalances/createCustomerBlockedBalance', async ({
                                                                                                                        id,
                                                                                                                        params
                                                                                                                    }) => {
    try {
        const response = await axios.post(`/blocked-balances/customers/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Customers

// ** Suppliers
export const getSupplierBlockedBalances = createAsyncThunk('blockedBalances/getSupplierBlockedBalances', async ({
                                                                                                                    id,
                                                                                                                    params
                                                                                                                }) => {
    try {
        const response = await axios.post(`/blocked-balances/suppliers/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getSupplierBlockedBalancesFilters = createAsyncThunk('blockedBalances/getSupplierBlockedBalancesFilters', async ({
                                                                                                                                  id,
                                                                                                                                  params
                                                                                                                              }) => {
    try {
        const response = await axios.post(`/blocked-balances/suppliers/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createSupplierBlockedBalance = createAsyncThunk('blockedBalances/createSupplierBlockedBalance', async ({
                                                                                                                        id,
                                                                                                                        params
                                                                                                                    }) => {
    try {
        const response = await axios.post(`/blocked-balances/departments/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Suppliers

// ** Departments
export const getDepartmentBlockedBalances = createAsyncThunk('blockedBalances/getDepartmentBlockedBalances', async ({
                                                                                                                        id,
                                                                                                                        params
                                                                                                                    }) => {
    try {
        const response = await axios.post(`/blocked-balances/users/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getDepartmentBlockedBalancesFilters = createAsyncThunk('blockedBalances/getDepartmentBlockedBalancesFilters', async ({
                                                                                                                                      id,
                                                                                                                                      params
                                                                                                                                  }) => {
    try {
        const response = await axios.post(`/blocked-balances/departments/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createDepartmentBlockedBalance = createAsyncThunk('blockedBalances/createDepartmentBlockedBalance', async ({
                                                                                                                            id,
                                                                                                                            params
                                                                                                                        }) => {
    try {
        const response = await axios.post(`/blocked-balances/suppliers/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Departments

// ** Checkouts
export const getCheckoutBlockedBalances = createAsyncThunk('blockedBalances/getCheckoutBlockedBalances', async ({
                                                                                                                    id,
                                                                                                                    params
                                                                                                                }) => {
    try {
        const response = await axios.post(`/blocked-balances/checkouts/${id}/list`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getCheckoutBlockedBalancesFilters = createAsyncThunk('blockedBalances/getCheckoutBlockedBalancesFilters', async ({
                                                                                                                                  id,
                                                                                                                                  params
                                                                                                                              }) => {
    try {
        const response = await axios.post(`/blocked-balances/checkouts/${id}/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createCheckoutBlockedBalance = createAsyncThunk('blockedBalances/createCheckoutBlockedBalance', async ({
                                                                                                                        id,
                                                                                                                        params
                                                                                                                    }) => {
    try {
        const response = await axios.post(`/blocked-balances/checkouts/${id}`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})
// ** End Checkouts

export const deleteBlockedBalance = createAsyncThunk('blockedBalances/deleteBlockedBalance', async (id) => {
    try {
        const response = await axios.delete(`/blocked-balances/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingBalances: false,
    loadingFilters: false,
    balances: [],
    filters: [],
    selectedValueSize: 0
}

export const blockedBalancesSlice = createSlice({
    name: 'blockedBalances',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getUserBlockedBalances.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getUserBlockedBalances.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getUserBlockedBalancesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getUserBlockedBalancesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
            })

            .addCase(getCustomerBlockedBalances.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getCustomerBlockedBalances.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getCustomerBlockedBalancesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCustomerBlockedBalancesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
            })

            .addCase(getSupplierBlockedBalances.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getSupplierBlockedBalances.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getSupplierBlockedBalancesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSupplierBlockedBalancesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
            })

            .addCase(getDepartmentBlockedBalances.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getDepartmentBlockedBalances.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getDepartmentBlockedBalancesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getDepartmentBlockedBalancesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
            })

            .addCase(getCheckoutBlockedBalances.pending, (state) => {
                state.loadingBalances = true
            })
            .addCase(getCheckoutBlockedBalances.fulfilled, (state, action) => {
                state.loadingBalances = false
                state.balances = action.payload
            })

            .addCase(getCheckoutBlockedBalancesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCheckoutBlockedBalancesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
            })
    }
})

export const {} = blockedBalancesSlice.actions

export default blockedBalancesSlice.reducer
