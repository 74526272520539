import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../utility/customAxios'

export const getRoles = createAsyncThunk('roles/getRoles', async () => {
    try {
        const response = await axios.get('/roles')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getRolesList = createAsyncThunk('roles/getRolesList', async (params) => {
    try {
        const response = await axios.post('/roles/list', params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getRole = createAsyncThunk('roles/getRole', async (id) => {
    try {
        const response = await axios.get(`/roles/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createRoles = createAsyncThunk('roles/createRoles', async (roles) => {
    try {
        const response = await axios.post('/roles', roles)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteRoles = createAsyncThunk('roles/deleteRoles', async (id) => {
    try {
        const response = await axios.delete(`/roles/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const rolesSetPermission = createAsyncThunk('roles/rolesSetPermission', async (roles) => {
    try {
        const response = await axios.post('/roles/setPermissions', roles)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: [],
        selectedRole: null,
        loadingRoles: false,
        loadingRole: false,
        loadingRolesList: false,
        rolesList: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getRoles.pending, (state) => {
                state.loadingRoles = true
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.roles = action.payload
                state.loadingRoles = false
            })

            .addCase(getRole.pending, (state) => {
                state.selectedRole = null
                state.loadingRole = true
            })
            .addCase(getRole.fulfilled, (state, action) => {
                state.selectedRole = action.payload
                state.loadingRole = false
            })

            .addCase(getRolesList.pending, (state) => {
                state.loadingRolesList = true
            })
            .addCase(getRolesList.fulfilled, (state, action) => {
                state.loadingRolesList = false
                state.rolesList = action.payload
            })
    }
})

export const {} = rolesSlice.actions

export default rolesSlice.reducer
