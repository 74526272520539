import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

/* DocumentProperties */
export const createDocumentProperties = createAsyncThunk('departments/createDocumentProperties', async (params) => {
    try {
        const response = await axios.post('/document-properties', params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocumentProperties = createAsyncThunk('departments/deleteDocumentProperties', async (documentPropertyId) => {
    try {
        const response = await axios.delete(`/document-properties/${documentPropertyId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentProperties = createAsyncThunk('documents/getDocumentProperties', async (params) => {
    try {
        const response = await axios.post(`/document-properties/list`, params)
        return {
            properties: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentProperty = createAsyncThunk('documents/getDocumentProperty', async (id) => {
    try {
        const response = await axios.get(`/document-properties/${id}`)
        return response?.data.items
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentPropertiesFilters = createAsyncThunk('documents/getDocumentPropertiesFilters', async (params) => {
    try {
        const response = await axios.post(`/document-properties/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentPropertiesSearch = createAsyncThunk('documents/getDocumentPropertiesSearch', async (params) => {
    const data = []
    try {
        const response = await axios.get('/document-properties', {
            params
        })
        response?.data?.items.map(item => data.push({value: item?.id, label: item?.title}))
        return data
    } catch (e) {
        console.error(e)
    }
})

/* DocumentCategoryProperties */
export const createDocumentCategoryProperties = createAsyncThunk('documents/createDocumentCategoryProperties', async (params) => {
    try {
        const response = await axios.post(`/document-categories/${params?.documentCategoryId}/properties/${params?.propertyId}`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocumentCategoryProperties = createAsyncThunk('documents/deleteDocumentCategoryProperties', async (params) => {
    try {
        const response = await axios.delete(`/document-categories/${params?.documentCategoryId}/properties/${params?.propertyId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoryProperties = createAsyncThunk('documents/getDocumentCategoryProperties', async (params) => {
    try {
        const response = await axios.post(`/document-categories/list`, params)
        return {
            categories: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoryPropertiesFilters = createAsyncThunk('documents/getDocumentCategoryPropertiesFilters', async (params) => {
    try {
        const response = await axios.post(`/document-properties/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    properties: [],
    appliedFilters: [],
    filters: [],
    documentsFilters: [],
    categories: [],
    categoriesSearch: [],
    propertiesSearch: [],
    selectedValueSize: 0,
    selectedValueSizeCategory: 0,
    selectedProperty: null,
    loadingProperty: false,
    loadingProperties: false,
    loadingPropertiesSearch: false
}

export const documentPropertiesSlice = createSlice({
    name: 'documentProperties',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentProperties.pending, (state) => {
                state.loadingProperties = true
            })
            .addCase(getDocumentProperties.fulfilled, (state, action) => {
                state.properties = action?.payload?.properties
                state.total = action?.payload?.total
                state.loadingProperties = false
            })

            .addCase(getDocumentPropertiesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getDocumentPropertiesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getDocumentProperty.pending, (state) => {
                state.loadingProperty = true
            })
            .addCase(getDocumentProperty.fulfilled, (state, action) => {
                state.selectedProperty = action?.payload
                state.loadingProperty = false
            })

            .addCase(getDocumentPropertiesSearch.pending, (state) => {
                state.loadingPropertiesSearch = true
            })
            .addCase(getDocumentPropertiesSearch.fulfilled, (state, action) => {
                state.propertiesSearch = action?.payload
                state.loadingPropertiesSearch = false
            })

    }
})

export const {} = documentPropertiesSlice.actions

export default documentPropertiesSlice.reducer
