import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getCategories = createAsyncThunk('categories/getProductsCategories', async (params) => {
    try {
        const response = await axios.post('/categories/list', params)
        return {
            categories: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

export const getProductCategory = createAsyncThunk('categories/getProductsCategory', async (id) => {
    try {
        const response = await axios.get(`/categories/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createProductCategory = createAsyncThunk('categories/createProductsCategory', async (params) => {
    try {
        const response = await axios.post('/categories', params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editCategories = createAsyncThunk('categories/editProductsCategories', async (params) => {
    try {
        const response = await axios.put(`/categories`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteProductCategory = createAsyncThunk('categories/deleteProductsCategories', async (id) => {
    try {
        const response = await axios.delete(`/categories/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getCategoriesFilters = createAsyncThunk('categories/getProductsCategoriesFilters', async (params) => {
    try {
        const response = await axios.post(`/categories/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

export const getCategoriesSearch = createAsyncThunk('categories/getCategoriesSearch', async (params) => {
    const data = []
    try {
        const response = await axios.get(`/categories`, params)
        response.data.items.map(item => data.push({value: item.id, label: item.code}))
        return data
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    categories: [],
    appliedFilters: [],
    filters: [],
    loadingCategories: false,
    selectedCategory: null,
    loadingCategory: null,
    loadingFilters: false,
    categorySearch: [],
    loadingProductCategorySearch: false
}

export const CategoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.loadingCategories = true
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action?.payload?.categories
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingCategories = false
            })

            .addCase(getProductCategory.pending, (state) => {
                state.loadingCategory = true
            })
            .addCase(getProductCategory.fulfilled, (state, action) => {
                state.selectedCategory = action?.payload
                state.loadingCategory = false
            })

            .addCase(getCategoriesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCategoriesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload
                state.loadingFilters = false
            })

            .addCase(getCategoriesSearch.pending, (state) => {
                state.loadingProductsCategoriesSearch = true
            })
            .addCase(getCategoriesSearch.fulfilled, (state, action) => {
                state.categorySearch = action?.payload
                state.loadingProductsCategoriesSearch = false
            })
    }
})

export const {} = CategoriesSlice.actions

export default CategoriesSlice.reducer
