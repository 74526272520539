import axios from '../../../utility/customAxios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
        return axios.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken()
        })
    }

    forgotPassword(...args) {
        return axios.post(this.jwtConfig.forgetPasswordEndpoint, ...args)
    }

    resetPassword(token, ...args) {
        return axios.post(`${this.jwtConfig.resetPasswordEndpoint}/${token}`, ...args)
    }

    sendTwoStepVerificationCode(...args) {
        return axios.post(this.jwtConfig.twoStepVerificationEndpoint, ...args)
    }

    getAbilities(...args) {
        return axios.get(this.jwtConfig.abilities, ...args)
    }

    getActUsers(...args) {
        return axios.post(this.jwtConfig.actUsers, ...args)
    }

    getActToken(...args) {
        return axios.post(this.jwtConfig.actToken, ...args)
    }

    returnToMain(...args) {
        return axios.get(this.jwtConfig.actReturnToMain, ...args)
    }

    verifyUser(token, ...args) {
        return axios.post(`${this.jwtConfig.verifyUser}/${token}`, ...args)
    }

    sendNotificationVerifyUser(...args) {
        return axios.post(`${this.jwtConfig.verifyUserSendNotification}`, ...args)
    }
}
