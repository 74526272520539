import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../utility/customAxios'

export const getCountries = createAsyncThunk('locations/getCountries', async () => {
    try {
        const response = await axios.get('/countries')
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }

})

export const getCountry = createAsyncThunk('locations/getCountry', async (countryId) => {
    try {
        const response = await axios.get(`/countries/${countryId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }

})

export const getCities = createAsyncThunk('locations/getCities', async (countryId) => {
    try {
        const response = await axios.get(`/countries/${countryId}/cities`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getCity = createAsyncThunk('locations/getCity', async ({countryId, cityId}) => {
    try {
        const response = await axios.get(`/countries/${countryId}/cities/${cityId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getDistricts = createAsyncThunk('locations/getDistricts', async ({countryId, cityId}) => {
    try {
        const response = await axios.get(`/countries/${countryId}/cities/${cityId}/districts`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getDistrict = createAsyncThunk('locations/getDistrict', async ({countryId, cityId, districtId}) => {
    try {
        const response = await axios.get(`/countries/${countryId}/cities/${cityId}/districts/${districtId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const locationSlice = createSlice({
    name: 'locations',
    initialState: {
        countries: [],
        cities: [],
        districts: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCountries.pending, (state) => {
                state.countries = []
                state.cities = []
                state.districts = []
            })
            .addCase(getCountries.fulfilled, (state, action) => {
                state.countries = action.payload
            })
            .addCase(getCities.pending, (state) => {
                state.districts = []
            })
            .addCase(getCities.fulfilled, (state, action) => {
                state.cities = action.payload
            })
            .addCase(getDistricts.fulfilled, (state, action) => {
                state.districts = action.payload
            })
    }
})

export const {} = locationSlice.actions

export default locationSlice.reducer
