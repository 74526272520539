import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getCustomers = createAsyncThunk('customers/getCustomers', async (params) => {
    try {
        const response = await axios.post('/customers/list', params)
        return {
            customers: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCustomer = createAsyncThunk('customers/getCustomer', async (id) => {
    try {
        const response = await axios.get(`/customers/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createCustomer = createAsyncThunk('customers/createCustomer', async (customers) => {
    try {
        const response = await axios.post('/customers', customers)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editCustomer = createAsyncThunk('customers/editCustomer', async (customers) => {
    try {
        const response = await axios.put(`/customers`, customers)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const deleteCustomer = createAsyncThunk('customers/deleteCustomer', async (id) => {
    try {
        const response = await axios.delete(`/customers/${id}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getCustomersFilters = createAsyncThunk('customers/getCustomersFilters', async (params) => {
    try {
        const response = await axios.post(`/customers/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCustomerBalance = createAsyncThunk('customers/getCustomerBalance', async (id) => {
    try {
        const response = await axios.get(`/balances/customers/${id}`)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

export const getDashboardCustomer = createAsyncThunk('customers/getDashboardCustomer', async () => {
    try {
        const response = await axios.get(`/dashboard/customerCounts`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    customers: [],
    appliedFilters: [],
    selectedCustomer: null,
    filters: [],
    loadingCustomers: false,
    loadingFilters: false,
    loadingCustomer: false,
    balances: [],
    loadingCustomerBalance: false,
    dashboardCustomer: [],
    loadingDashboardCustomer: false
}

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomers.pending, (state) => {
                state.loadingCustomers = true
            })
            .addCase(getCustomers.fulfilled, (state, action) => {
                state.customers = action?.payload?.customers
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingCustomers = false
            })

            .addCase(getCustomer.pending, (state) => {
                state.loadingCustomer = true
            })
            .addCase(getCustomer.fulfilled, (state, action) => {
                state.selectedCustomer = action.payload
                state.loadingCustomer = false
            })

            .addCase(getCustomersFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCustomersFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getCustomerBalance.pending, (state) => {
                state.loadingCustomerBalance = true
            })
            .addCase(getCustomerBalance.fulfilled, (state, action) => {
                state.loadingCustomerBalance = false
                state.balances = action.payload
            })

            .addCase(getDashboardCustomer.pending, (state) => {
                state.loadingDashboardCustomer = true
            })
            .addCase(getDashboardCustomer.fulfilled, (state, action) => {
                state.dashboardCustomer = action.payload
                state.loadingDashboardCustomer = false
            })
    }
})

export const {} = customersSlice.actions

export default customersSlice.reducer
