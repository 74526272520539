import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getTransactionPaymentTypes = createAsyncThunk('transactions/getTransactionPaymentTypes', async () => {
    try {
        const response = await axios.get('/transaction-payment-types')
        return {
            transactionPaymentTypes: response?.data?.items
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionTypes = createAsyncThunk('transactions/getTransactionTypes', async (params) => {
    try {
        const response = await axios.post('/transaction-types/list', params)
        return {
            transactionTypes: response?.data?.items
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionTypesFilters = createAsyncThunk('transactions/getTransactionTypesFilters', async (params) => {
    try {
        const response = await axios.post('/transaction-types/list/filters', params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionPaymentType = createAsyncThunk('transactions/getTransactionPaymentType', async (code) => {
    try {
        const response = await axios.get(`/transaction-payment-types/codes/${code}`)
        return {
            transactionPaymentType: response?.data?.items?.children
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionTypeCode = createAsyncThunk('transactions/getTransactionTypeCode', async (code) => {
    try {
        const response = await axios.get(`/transaction-types/codes/${code}`)
        return {
            transactionTypeCode: response?.data?.items?.children
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransactions = createAsyncThunk('transactions/getTransactions', async (params) => {
    try {
        const response = await axios.post(`/transactions/list`, params)
        return {
            transactions: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionsAmount = createAsyncThunk('transactions/getTransactionsAmount', async (params) => {
    try {
        const response = await axios.post(`/transactions/list/amounts`, params)
        return {
            transactionsAmount: response?.data?.items
        }
    } catch (e) {
        console.error(e)
    }
})

export const getTransaction = createAsyncThunk('transactions/getTransaction', async (id) => {
    try {
        const response = await axios.get(`/transactions/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionWithNumber = createAsyncThunk('transactions/getTransactionWithNumber', async (number) => {
    try {
        const response = await axios.get(`/transactions/number/${number}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionsFilters = createAsyncThunk('transactions/getTransactionsFilters', async (params) => {
    try {
        const response = await axios.post(`/transactions/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const createTransaction = createAsyncThunk('transactions/createTransaction', async (params) => {
    try {
        const response = await axios.post(`/transactions`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const getCheckouts = createAsyncThunk('transactions/getCheckouts', async (params) => {
    try {
        const response = await axios.get(`/checkouts`, {
            params
        })
        return {
            checkouts: response?.data?.items
        }
    } catch (e) {
        console.error(e)
    }
})

export const getExchangeRates = createAsyncThunk('transactions/getExchangeRates', async (params) => {
    try {
        const response = await axios.get(`/exchange-rates`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const calculateExchangeRate = createAsyncThunk('transactions/calculateExchangeRate', async (params) => {
    try {
        const response = await axios.post(`/exchange-rates/calculate`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionNumber = createAsyncThunk('transactions/getTransactionNumber', async (number) => {
    try {
        const response = await axios.get(`/transactions/number/${number}`)
        return {
            transactionNumber: response?.data?.items
        }
    } catch (e) {
        console.error(e)
    }
})

export const deleteTransaction = createAsyncThunk('transactions/deleteTransaction', async (params) => {
    try {
        const response = await axios.post(`/transactions/cancel`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const getTransactionPayments = createAsyncThunk('transactions/getTransactionPayments', async (params) => {
    try {
        const response = await axios.get(`/transactions/${params?.transactionNumber}/payments`)
        return {
            transactionPayments: response?.data?.items,
            transactionPaymentsTotal: response?.data?.size,
            balance: response?.data?.balance
        }
    } catch (e) {
        console.error(e)
    }
})

export const createTransactionPayment = createAsyncThunk('transactions/createTransactionPayment', async (params) => {
    try {
        const response = await axios.post(`/transactions/${params?.transactionNumber}/payments`, params)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editTransactionPayment = createAsyncThunk('transactions/editTransactionPayment', async (params) => {
    try {
        const response = await axios.post(`/transactions/${params?.transactionNumber}/payments/state`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const controlTransactionPayment = createAsyncThunk('transactions/controlTransactionPayment', async (params) => {
    try {
        const response = await axios.put(`/transactions/control`, params)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const deleteTransactionPayment = createAsyncThunk('transactions/deleteTransactionPayment', async (params) => {
    try {
        const response = await axios.delete(`/transactions/${params?.transactionNumber}/payments/${params?.paymentId}`, params)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getPDF = createAsyncThunk('transactions/getPDF', async (params) => {
    try {
        const response = await axios.post('pdf/transactions/list', params, {
            timeout: 1000 * 60 * 2
        })
        return {
            pdf: response?.data
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingTransactionPaymentTypes: false,
    loadingTransactionPaymentType: false,
    transactionPaymentTypes: [],
    transactionPaymentType: [],
    transactionTypes: [],
    transactionNumber: [],
    loadingTransactionNumber: false,
    checkouts: [],
    loadingCheckouts: false,
    total: 0,
    transactions: [],
    loadingTransaction: false,
    loadingTransactionTypes: false,
    selectedTransaction: null,
    loadingTransactions: false,
    loadingTransactionsFilters: false,
    filters: [],
    loadingTransactionTypesFilters: false,
    transactionTypesFilters: [],
    selectedValueSize: 0,
    transactionPayments: [],
    transactionPaymentsTotal: 0,
    loadingTransactionPayments: false,
    balance: null,
    transactionTypeCode: [],
    loadingTransactionTypeCode: false,
    loadingPDF: false,
    transactionsAmount: [],
    loadingTransactionsAmount: false
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTransactions.pending, (state) => {
                state.loadingTransactions = true
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.transactions = action?.payload?.transactions
                state.total = action?.payload?.total
                state.loadingTransactions = false
            })

            .addCase(getTransactionsAmount.pending, (state) => {
                state.loadingTransactionsAmount = true
            })
            .addCase(getTransactionsAmount.fulfilled, (state, action) => {
                state.transactionsAmount = action?.payload?.transactionsAmount
                state.loadingTransactionsAmount = false
            })

            .addCase(getTransactionsFilters.pending, (state) => {
                state.loadingTransactionsFilters = true
            })
            .addCase(getTransactionsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingTransactionsFilters = false
            })

            .addCase(getTransaction.pending, (state) => {
                state.loadingTransaction = true
            })
            .addCase(getTransaction.fulfilled, (state, action) => {
                state.selectedTransaction = action?.payload
                state.loadingTransaction = false
            })

            .addCase(getTransactionWithNumber.pending, (state) => {
                state.loadingTransaction = true
            })
            .addCase(getTransactionWithNumber.fulfilled, (state, action) => {
                state.selectedTransaction = action?.payload
                state.loadingTransaction = false
            })

            .addCase(getTransactionPaymentTypes.pending, (state) => {
                state.loadingTransactionPaymentTypes = true
            })
            .addCase(getTransactionPaymentTypes.fulfilled, (state, action) => {
                state.transactionPaymentTypes = action?.payload?.transactionPaymentTypes
                state.loadingTransactionPaymentTypes = false
            })

            .addCase(getTransactionPaymentType.pending, (state) => {
                state.loadingTransactionPaymentType = true
            })
            .addCase(getTransactionPaymentType.fulfilled, (state, action) => {
                state.transactionPaymentType = action?.payload?.transactionPaymentType
                state.loadingTransactionPaymentType = false
            })

            .addCase(getTransactionTypeCode.pending, (state) => {
                state.loadingTransactionTypeCode = true
            })
            .addCase(getTransactionTypeCode.fulfilled, (state, action) => {
                state.transactionTypeCode = action?.payload?.transactionTypeCode
                state.loadingTransactionTypeCode = false
            })

            .addCase(getTransactionTypes.pending, (state) => {
                state.loadingTransactionTypes = true
            })
            .addCase(getTransactionTypes.fulfilled, (state, action) => {
                state.transactionTypes = action?.payload?.transactionTypes
                state.loadingTransactionTypes = false
            })

            .addCase(getCheckouts.pending, (state) => {
                state.loadingCheckouts = true
            })
            .addCase(getCheckouts.fulfilled, (state, action) => {
                state.checkouts = action?.payload?.checkouts
                state.loadingCheckouts = false
            })

            .addCase(getTransactionTypesFilters.pending, (state) => {
                state.loadingTransactionTypesFilters = true
            })
            .addCase(getTransactionTypesFilters.fulfilled, (state, action) => {
                state.transactionTypesFilters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingTransactionTypesFilters = false
            })

            .addCase(getTransactionNumber.pending, (state) => {
                state.loadingTransactionNumber = true
            })
            .addCase(getTransactionNumber.fulfilled, (state, action) => {
                state.loadingTransactionNumber = false
                state.transactionNumber = action?.payload?.transactionNumber
            })

            .addCase(getTransactionPayments.pending, (state) => {
                state.loadingTransactionPayments = true
            })
            .addCase(getTransactionPayments.fulfilled, (state, action) => {
                state.loadingTransactionPayments = false
                state.transactionPayments = action?.payload?.transactionPayments
                state.transactionPaymentsTotal = action?.payload?.transactionPaymentsTotal
                state.balance = action?.payload?.balance
            })

            /* PDF */
            .addCase(getPDF.pending, (state) => {
                state.loadingPDF = true
            })
            .addCase(getPDF.fulfilled, (state, action) => {
                state.loadingPDF = false
                state.pdf = action?.payload
            })
    }
})

export const {
    reset
} = transactionsSlice.actions

export default transactionsSlice.reducer
