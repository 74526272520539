import axios from 'axios'
import toast from 'react-hot-toast'
import {AlertCircle} from 'react-feather'

// ** Configs
import appConfig from '@configs/appConfig'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

const getToken = () => {
    return JSON.parse(localStorage.getItem(jwtDefaultConfig.storageTokenKeyName))
}

const customAxios = axios.create({
    baseURL: `${appConfig.api}`,
    timeout: 30000
})

const requestHandler = request => {
    request.headers.Authorization = `Bearer ${getToken()}`
    request.headers.language = localStorage.getItem('i18nextLng')
    return request
}

const responseHandler = response => {
    if (response.status === 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
        localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)
        setTimeout(() => {
            window.location = '/'
        }, 300)
    }

    return response
}

const errorHandler = error => {
    if (error?.response?.status === 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
        localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)
        toast.error('Giriş sayfasına yönlendiriliyorsunuz.', {
            duration: 5000
        })
        setTimeout(() => {
            window.location = '/'
        }, 1500)

        return Promise.reject(error)
    }

    toast(
        <div dangerouslySetInnerHTML={{__html: error?.response?.data?.message || error?.response?.message || error?.message || 'Error !'}}/>,
        {
            icon: <AlertCircle size={20} color={'#FF5733'} />
        }
    )

    return Promise.reject(error)
}

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
)

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
)

export default customAxios
export {
    getToken
}

