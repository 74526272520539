import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '@src/utility/customAxios'

export const getCheckouts = createAsyncThunk('checkouts/getCheckouts', async (params) => {
    try {
        const response = await axios.post('/checkouts/list', params)
        return {
            checkouts: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCheckout = createAsyncThunk('checkouts/getCheckout', async (id) => {
    try {
        const response = await axios.get(`/checkouts/${id}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createCheckout = createAsyncThunk('checkouts/createCheckout', async (checkout) => {
    try {
        const response = await axios.post(`/checkouts`, checkout)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const editCheckout = createAsyncThunk('checkouts/editCheckout', async (checkout) => {
    try {
        const response = await axios.put(`/checkouts`, checkout)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const getCheckoutsFilters = createAsyncThunk('checkouts/getCheckoutsFilters', async (params) => {
    try {
        const response = await axios.post(`/checkouts/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCheckoutUsers = createAsyncThunk('checkouts/getCheckoutUsers', async (checkoutId, params) => {
    try {
        const response = await axios.post(`/checkouts/${checkoutId}/users/list`, params)
        return {
            checkoutUsers: response?.data?.items,
            totalUsers: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const createUserCheckout = createAsyncThunk('checkouts/createUserCheckout', async ({id, userId}) => {
    try {
        const response = await axios.post(`/checkouts/${id}/users/${userId}`)
        return response?.data
    } catch (e) {
        console.error(e)
    }
})

export const deleteUserCheckout = createAsyncThunk('checkouts/deleteUserCheckouts', async ({id, userId}) => {
    try {
        const response = await axios.delete(`/checkouts/${id}/users/${userId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getCheckoutUsersFilters = createAsyncThunk('checkouts/getCheckoutUsersFilters', async (checkoutId, params) => {
    try {
        const response = await axios.post(`/checkouts/${checkoutId}/users/list/filters`, params)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

export const getCheckoutBalance = createAsyncThunk('checkouts/getCheckoutBalance', async (id) => {
    try {
        const response = await axios.get(`/balances/checkouts/${id}`)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    loadingCheckouts: false,
    checkouts: [],
    checkoutUsers: [],
    filters: [],
    checkoutUserFilters: [],
    loadingCheckoutUsers: false,
    loadingCheckoutsFilters: false,
    loadingCheckoutsUserFilters: false,
    total: 0,
    totalUsers: 0,
    loadingCheckout: false,
    selectedCheckout: null,
    balances: [],
    loadingCheckoutBalance: false,
    selectedValueSize: 0
}

export const checkoutsSlice = createSlice({
    name: 'checkouts',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCheckouts.pending, (state) => {
                state.loadingCheckouts = true
            })
            .addCase(getCheckouts.fulfilled, (state, action) => {
                state.checkouts = action?.payload?.checkouts
                state.total = action?.payload?.total
                state.loadingCheckouts = false
            })

            .addCase(getCheckout.pending, (state) => {
                state.loadingCheckout = true
            })
            .addCase(getCheckout.fulfilled, (state, action) => {
                state.selectedCheckout = action?.payload
                state.loadingCheckout = false
            })

            .addCase(getCheckoutsFilters.pending, (state) => {
                state.loadingCheckoutsFilters = true
            })
            .addCase(getCheckoutsFilters.fulfilled, (state, action) => {
                state.filters = action?.payload.filters
                state.selectedValueSize = action?.payload.selectedValueSize
                state.loadingCheckoutsFilters = false
            })

            .addCase(getCheckoutUsers.pending, (state) => {
                state.loadingCheckoutUsers = true
            })
            .addCase(getCheckoutUsers.fulfilled, (state, action) => {
                state.checkoutUsers = action?.payload?.checkoutUsers
                state.totalUsers = action?.payload?.totalUsers
                state.loadingCheckoutUsers = false
            })

            .addCase(getCheckoutUsersFilters.pending, (state) => {
                state.loadingCheckoutsUserFilters = true
            })
            .addCase(getCheckoutUsersFilters.fulfilled, (state, action) => {
                state.checkoutUserFilters = action?.payload
                state.loadingCheckoutsUserFilters = false
            })

            .addCase(getCheckoutBalance.pending, (state) => {
                state.loadingCheckoutBalance = true
            })
            .addCase(getCheckoutBalance.fulfilled, (state, action) => {
                state.balances = action?.payload
                state.loadingCheckoutBalance = false
            })
    }
})

export const {} = checkoutsSlice.actions

export default checkoutsSlice.reducer
