import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

/* Categories */
export const getDocumentCategories = createAsyncThunk('documents/getDocumentCategories', async (params) => {
    try {
        const response = await axios.post(`/document-categories/list`, params)
        return {
            categories: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoriesFilters = createAsyncThunk('documents/getDocumentCategoriesFilters', async (params) => {
    try {
        const response = await axios.post(`/document-categories/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategory = createAsyncThunk('documents/getDocumentCategory', async (documentCategoryId) => {
    try {
        const response = await axios.get(`/document-categories/${documentCategoryId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})
export const getDocumentCategoriesSearch = createAsyncThunk('documents/getDocumentCategoriesSearch', async (params) => {
    try {
        const response = await axios.get(`/document-categories`, params)
        return {
            categoriesSearch: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})
export const editDocumentCategory = createAsyncThunk('documents/editDocumentCategory', async (params) => {
    try {
        const response = await axios.put('/document-categories', params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const createDocumentCategory = createAsyncThunk('documents/createDocumentCategory', async (params) => {
    try {
        const response = await axios.post('/document-categories', params)
        return response
    } catch (e) {
        console.error(e)
    }
})
export const deleteDocumentCategory = createAsyncThunk('documents/deleteDocumentCategory', async (documentCategoryId) => {
    try {
        const response = await axios.delete(`/document-categories/${documentCategoryId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    categories: [],
    appliedFilters: [],
    filters: [],
    selectedValueSize: 0,
    selectedCategory: null,
    loadingCategory: false,
    loadingCategories: false
}

export const documentCategoriesSlice = createSlice({
    name: 'documentCategories',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentCategories.pending, (state) => {
                state.loadingCategories = true
            })
            .addCase(getDocumentCategories.fulfilled, (state, action) => {
                state.categories = action?.payload?.categories
                state.total = action?.payload?.total
                state.loadingCategories = false
            })

            .addCase(getDocumentCategoriesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getDocumentCategoriesFilters.fulfilled, (state, action) => {
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
                state.loadingFilters = false
            })

            .addCase(getDocumentCategory.pending, (state) => {
                state.loadingCategory = true
            })
            .addCase(getDocumentCategory.fulfilled, (state, action) => {
                state.selectedCategory = action?.payload
                state.loadingCategory = false
            })

    }
})

export const {} = documentCategoriesSlice.actions

export default documentCategoriesSlice.reducer
