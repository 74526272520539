// ** Reducers
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import location from './location'
import roles from './roles'
import permissions from './permissions'
import settings from './settings'
import notifications from './notification'
import users from '../views/modules/User/Users/store'
import accountSettings from '../views/modules/AccountSettings/store'
import departments from '../views/modules/Department/Departments/store'
import products from '../views/modules/Product/Products/store'
import properties from '../views/modules/Product/Properties/store'
import suppliers from '../views/modules/Supplier/Suppliers/store'
import supplierAddresses from '../views/modules/Supplier/SupplierAddresses/store'
import categories from '../views/modules/Product/Categories/store'
import customers from '../views/modules/Customer/Customers/store'
import customerAddresses from '../views/modules/Customer/CustomerAddresses/store'
import userPhones from '../views/modules/User/UserPhones/store'
import supplierWorkers from '../views/modules/Supplier/SupplierWorkers/store'
import userDepartments from '../views/modules/User/UserDepartments/store'
import currencies from '../views/modules/Accounting/Currencies/store'
import chequeBooks from '../views/modules/Accounting/ChequeBooks/store'
import cheques from '../views/modules/Accounting/Cheques/store'
import checkouts from '../views/modules/Accounting/Checkouts/store'
import dashboard from '../views/modules/Dashboard/store'
import blockedBalances from '../views/modules/Accounting/BlockedBalances/store'
import banks from '../views/modules/Accounting/Banks/store'
import bankAccounts from '../views/modules/Accounting/BankAccounts/store'
import myBankAccounts from '../views/modules/Accounting/MyBankAccounts/store'
import exchangeRates from '../views/modules/Accounting/ExchangeRates/store'
import transactions from '../views/modules/Accounting/Transactions/store'
import myTransactions from '../views/modules/Accounting/MyTransactions/store'
import baseCurrencies from '../views/modules/Accounting/BaseCurrencies/store'
import balanceLimits from '../views/modules/Accounting/BalanceLimits/store'
import balance from '../views/modules/Accounting/Balance/store'
import taxes from '../views/modules/Product/Taxes/store'
import productUnits from '../views/modules/Product/ProductUnits/store'
import taxOffices from '../views/modules/Accounting/TaxOffices/store'
import prefixes from '../views/modules/Prefixes/store'
import userCodes from '../views/modules/User/UserCodes/store'
import documents from '../views/modules/Document/Documents/store'
import documentProperties from '../views/modules/Document/DocumentProperties/store'
import documentCategories from '../views/modules/Document/DocumentCategories/store'
import warehouses from '../views/modules/Warehouse/Warehouses/store'
import deliveryReceipts from '../views/modules/Warehouse/DeliveryReceipts/store'
import payments from '../views/modules/Warehouse/Payments/store'
import reports from '../views/modules/Accounting/Reports/store'
import expenses from '../views/modules/Accounting/Expenses/store'

const rootReducer = {
    auth,
    navbar,
    layout,
    location,
    users,
    roles,
    notifications,
    permissions,
    accountSettings,
    departments,
    settings,
    products,
    properties,
    suppliers,
    supplierAddresses,
    supplierWorkers,
    categories,
    customers,
    customerAddresses,
    userPhones,
    userDepartments,
    currencies,
    chequeBooks,
    cheques,
    checkouts,
    dashboard,
    blockedBalances,
    banks,
    bankAccounts,
    myBankAccounts,
    exchangeRates,
    transactions,
    myTransactions,
    baseCurrencies,
    balanceLimits,
    balance,
    taxes,
    productUnits,
    taxOffices,
    prefixes,
    userCodes,
    documents,
    documentProperties,
    documentCategories,
    warehouses,
    deliveryReceipts,
    payments,
    reports,
    expenses
}

export default rootReducer
