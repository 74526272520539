import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getSupplierAddresses = createAsyncThunk('supplierAddresses/getSupplierAddresses', async ({id, params}) => {
    try {
        const response = await axios.post(`/suppliers/${id}/addresses/list`, params)
        return {
            addresses: response?.data?.items,
            total: response?.data?.size
        }
    } catch (e) {
        console.error(e)
    }
})

export const getSupplierAddress = createAsyncThunk('supplierAddresses/getSupplierAddress', async ({id, addressId}) => {
    try {
        const response = await axios.get(`/suppliers/${id}/addresses/${addressId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createSupplierAddress = createAsyncThunk('supplierAddresses/createSupplierAddress', async ({id, supplierAddress}) => {
    try {
        const response = await axios.post(`/suppliers/${id}/addresses`, supplierAddress)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editSupplierAddress = createAsyncThunk('supplierAddresses/editSupplierAddress', async ({id, supplierAddress}) => {
    try {
        const response = await axios.put(`/suppliers/${id}/addresses`, supplierAddress)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteSupplierAddress = createAsyncThunk('supplierAddresses/deleteSuppliers', async ({id, addressId}) => {
    try {
        const response = await axios.delete(`/suppliers/${id}/addresses/${addressId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getSuppliersAddressesFilters = createAsyncThunk('supplierAddresses/getSuppliersFilters', async ({id, params}) => {
    try {
        const response = await axios.post(`/suppliers/${id}/addresses/list/filters`, params)
        return {
            filters: response?.data?.items,
            selectedValueSize: response?.data?.selectedValueSize
        }
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    addresses: [],
    appliedFilters: [],
    selectedAddress: null,
    filters: [],
    loadingAddresses: false,
    loadingFilters: false,
    loadingAddress: false,
    selectedValueSize: 0
}

export const supplierAddressesSlice = createSlice({
    name: 'supplierAddresses',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSupplierAddresses.pending, (state) => {
                state.loadingAddresses = true
            })
            .addCase(getSupplierAddresses.fulfilled, (state, action) => {
                state.addresses = action?.payload?.addresses
                state.total = action?.payload?.total
                state.loadingAddresses = false
            })

            .addCase(getSupplierAddress.pending, (state) => {
                state.loadingAddress = true
            })
            .addCase(getSupplierAddress.fulfilled, (state, action) => {
                state.selectedAddress = action.payload
                state.loadingAddress = false
            })

            .addCase(getSuppliersAddressesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getSuppliersAddressesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action?.payload?.filters
                state.selectedValueSize = action?.payload?.selectedValueSize
            })
    }
})

export const {} = supplierAddressesSlice.actions

export default supplierAddressesSlice.reducer
