import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Utils
import axios from '../../../../../utility/customAxios'

export const getCustomerAddresses = createAsyncThunk('customerAddresses/getCustomerAddresses', async ({id, params}) => {
    try {
        const response = await axios.post(`/customers/${id}/addresses/list`, params)
        return {
            addresses: response?.data?.items,
            total: response?.data?.size,
            appliedFilters: response?.data.filters
        }
    } catch (e) {
        console.error(e)
    }
})

export const getCustomerAddress = createAsyncThunk('customerAddresses/getCustomerAddress', async ({id, addressId}) => {
    try {
        const response = await axios.get(`/customers/${id}/addresses/${addressId}`)
        return response?.data?.items
    } catch (e) {
        console.error(e)
    }
})

export const createCustomerAddress = createAsyncThunk('customerAddresses/createCustomerAddress', async ({id, customerAddress}) => {
    try {
        const response = await axios.post(`/customers/${id}/addresses`, customerAddress)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const editCustomerAddress = createAsyncThunk('customerAddresses/editCustomerAddress', async ({id, customerAddress}) => {
    try {
        const response = await axios.put(`/customers/${id}/addresses`, customerAddress)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const deleteCustomerAddress = createAsyncThunk('customerAddresses/deleteCustomerAddress', async ({id, addressId}) => {
    try {
        const response = await axios.delete(`/customers/${id}/addresses/${addressId}`)
        return response
    } catch (e) {
        console.error(e)
    }
})

export const getCustomerAddressesFilters = createAsyncThunk('customerAddresses/getCustomerAddressesFilters', async (id) => {
    try {
        const response = await axios.get(`/customers/${id}/addresses/filters`)
        return response.data.items
    } catch (e) {
        console.error(e)
    }
})

const initialState = {
    total: null,
    addresses: [],
    appliedFilters: [],
    selectedAddress: null,
    filters: [],
    loadingAddresses: false,
    loadingFilters: false,
    loadingAddress: false
}

export const customerAddressesSlice = createSlice({
    name: 'customerAddresses',
    initialState,
    reducers: {
        reset() {
            return {
                ...initialState
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getCustomerAddresses.pending, (state) => {
                state.loadingAddresses = true
            })
            .addCase(getCustomerAddresses.fulfilled, (state, action) => {
                state.addresses = action?.payload?.addresses
                state.total = action?.payload?.total
                state.appliedFilters = action?.payload?.appliedFilters
                state.loadingAddresses = false
            })

            .addCase(getCustomerAddress.pending, (state) => {
                state.loadingAddress = true
            })
            .addCase(getCustomerAddress.fulfilled, (state, action) => {
                state.selectedAddress = action.payload
                state.loadingAddress = false
            })

            .addCase(getCustomerAddressesFilters.pending, (state) => {
                state.loadingFilters = true
            })
            .addCase(getCustomerAddressesFilters.fulfilled, (state, action) => {
                state.loadingFilters = false
                state.filters = action.payload
            })
    }
})

export const {} = customerAddressesSlice.actions

export default customerAddressesSlice.reducer
